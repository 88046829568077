import { COLOR } from "../constants/color";

export const signatureColorSelect = (param: string | undefined): string => {
  switch (param) {
    case "splunk":
      return COLOR.SPLUNK;
    case "splunkITSI":
      return COLOR.SPLUNKITSI;
    case "appdynamics":
      return COLOR.APPDYNAMICS;
    case "splunkES":
      return COLOR.SPLUNKES;
    case "NexGuardWebkeeper":
      return COLOR.WEBLOG;
    case "guradicore":
      return COLOR.GUARDICORE;
    case "purestorage":
      return COLOR.PURESTORAGE;
    case "NexGuardILMS":
      return COLOR.NEXGUARDILMS;
      case "NexGuardTI":
      return COLOR.NEXGUARDTI;
    default:
      return "#fff";
  }
};
