/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from "react";
import {
  Swiper as SwiperComponent,
  SwiperSlide,
  SwiperRef,
} from "swiper/react"; // SwiperRef 추가
import { Navigation, Pagination, Autoplay } from "swiper"; // Autoplay 포함
import { Link, To } from "react-router-dom";
import { PATH } from "src/constants/path";
import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MainStyle from "../../style/MainStyle";
import { selectMobile } from "../../store/ui/uiSelector";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Swiper 인스턴스 타입

interface Service {
  title: string;
  desc: string;
  link: string;
  description: string;
  backgroundImage: string;
}

const services: Service[] = [
  {
    title: "분석 솔루션",
    desc: "Big Data & Observability",
    link: PATH.SPLUNK,
    description:
      "시스템의 로그, 메트릭, 트레이스를 실시간으로 수집하고 분석하여 문제를 신속하게 탐지하고 해결할 수 있도록 지원하며, 데이터 기반의 인사이트를 제공하여 AI를 기반으로 운영 효율성을 높이는 데 기여합니다.",
    backgroundImage: "../static/images/main/MainSection2_grid1.jpg",
  },
  {
    title: "보안 솔루션",
    desc: "Security",
    link: PATH.SPLUNKES,
    description:
      "네트워크 트래픽과 시스템 로그를 모니터링하고 분석하여 잠재적인 위협과 비정상적인 활동을 조기에 탐지함으로써 데이터 유출을 방지하고 기업의 사이버 보안을 강화하는 데 중요한 역할을 합니다.",
    backgroundImage: "../static/images/main/MainSection2_grid2.jpg",
  },
  {
    title: "인프라 솔루션",
    desc: "Infrastructure",
    link: PATH.PURESTORAGE,
    description:
      "세계적인 브랜드의 제품을 활용해 최첨단 인프라 솔루션을 구축하여 데이터 처리 성능과 안정성을 극대화하고 효율성을 높이며, 빠른 데이터 접근으로 경쟁력을 강화해 성공적인 디지털 전환을 지원합니다.",
    backgroundImage: "../static/images/main/MainSection2_grid3.jpg",
  },
];

export default function MainSection2() {
  const [hoveredIndex, setHoveredIndex] = useState(-1); // hover 상태 관리
  const isMobile = useSelector(selectMobile);
  const swiperRef = useRef<SwiperRef | null>(null); // SwiperRef 타입으로 수정
  const to = useRef<To>(null)
  const handleSlideClick = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index); // 클릭한 슬라이드로 이동
    }
  };

  return (
    <MainStyle.Section2>
      <p className="ourservices">OUR SERVICES</p>
      <div className="gridArea">
        {isMobile ? (
          <SwiperComponent
            modules={[Navigation, Pagination, Autoplay]} // Autoplay 모듈 추가
            ref={swiperRef} // Swiper 인스턴스를 ref에 할당
            spaceBetween={15} // 슬라이드 간격
            slidesPerView={1.25} // 한 번에 보여줄 슬라이드 수
            // navigation // 네비게이션 버튼 추가
            // pagination={{ clickable: true }} // 페이지네이션 추가
            centeredSlides={true} // 슬라이드 중앙 정렬
            autoplay={{
              delay: 2500, // 슬라이드 간격 (밀리초)
              disableOnInteraction: true, // 사용자 상호작용 시 자동 재생 유지
            }}
          >
            {services.map((service, index) => (
              <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
                <div
                  className="background"
                  style={{
                    backgroundImage: `url(${service.backgroundImage})`,
                    backgroundSize: "cover",
                    margin: "0", // 여백 제거
                    padding: "0", // 패딩 제거
                    borderRadius: "20px",
                  }} // 배경 이미지 설정
                  // eslint-disable-next-line no-restricted-globals
                  
                />
                <div className="inner">
                  <div className="title">{service.title}</div>
                  <Link to={service.link}>
                    {service.desc}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                  <div className="desc">{service.description}</div>
                </div>
              </SwiperSlide>
            ))}
          </SwiperComponent>
        ) : (
          services.map((service, index) => (
            <div
              className={`grid ${hoveredIndex === index ? "hovered" : ""}`}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              <div
                className="background"
                css={css`
                  background-image: url(${service.backgroundImage});
                `}
              />
              <div className="inner">
                <div className="title">{service.title}</div>
                <Link to={service.link}>
                  {service.desc} <FontAwesomeIcon icon={faArrowRight} />
                </Link>
                <div className="desc">{service.description}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </MainStyle.Section2>
  );
}
