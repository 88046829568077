/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-wrapper-object-types */

type MainContentType = {
  title: string;
  desc: {
    desc1: string;
    desc2: string;
  };
  subDesc: string;
  merit: {
    title: string[];
    desc: string[];
  };
  mentTitle: string;
  mentDesc: string;
  solutionURL: string;
  solutionImage: string;
};

export const solutionArray = (param: string | undefined): MainContentType => {
  switch (param) {
    case "splunk":
      return splunk;
    case "splunkITSI":
      return splunkITSI;
    case "appdynamics":
      return appdynamics;
    case "splunkES":
      return splunkES;
    case "NexGuardWebkeeper":
      return weblog;
    case "guradicore":
      return guradicore;
    case "purestorage":
      return purestorage;
    case "NexGuardILMS":
      return nexguardILMS;
    case "NexGuardTI":
      return nexguardTI;
    default:
      return normal;
  }
};

const splunk = {
  title: "SPLUNK",
  desc: {
    desc1: "세계최초 The Data-to-Everything",
    desc2:
      "머신 데이터를 이용한 <span class=impact>No.1 운영 </span>인텔리전스 플랫폼",
  },
  subDesc: "SOC, MES, 이상징후탐지, 정보유출까지 가능한 강력한 SIME 솔루션",
  merit: {
    title: [
      "실시간 데이터 분석",
      "강력한 검색 기능",
      "다양한 데이터 소스 지원",
      "시각화도구",
      "확장성",
    ],
    desc: [
      "Splunk는 실시간으로 데이터를 수집하고 분석할 수 있는 기능을 제공합니다.<br/><br/>사용자는 시스템의 상태를 즉시 파악하고, 문제를 신속하게 해결할 수 있습니다.",
      "Splunk는 대량의 데이터를 빠르게 검색할 수 있는 강력한 검색 엔진을 갖추고 있습니다.<br/><br/>사용자는 간단한 SPL를 작성하여 필요한 정보를 쉽게 찾을 수 있으며, 데이터에서 인사이트를 도출하는 데 큰 도움이 됩니다.",
      "Splunk는 로그 파일, 데이터베이스, 클라우드 서비스 등 다양한 데이터 소스를 지원합니다.<br/><br/>여러 시스템에서 발생하는 데이터를 통합하여 분석할 수 있으며, 전체적인 비즈니스 인사이트를 확보할 수 있습니다.",
      "Splunk는 데이터를 시각적으로 표현할 수 있는 다양한 도구를 제공합니다.<br/><br/>차트, 그래프, 대시보드 등을 통해 복잡한 데이터를 쉽게 이해하고, 중요한 정보를 한눈에 파악할 수 있습니다.",
      "Splunk는 기업의 성장에 맞춰 확장할 수 있는 유연성을 제공합니다.<br/><br/>소규모 환경에서 시작하여 필요에 따라 시스템을 확장할 수 있으며, 대량의 데이터를 처리할 수 있는 능력을 갖추고 있습니다.",
    ],
  },
  mentTitle: "Turning data into doing",
  mentDesc:
    "Splunk는 원시 데이터만 보유하는 것에서 데이터를 시각적으로 볼 수 있는 능력을 제공하므로 매우 짧은 시간 내에 현명한 회사 결정을 내릴 수 있습니다.",
  solutionURL: "https://www.splunk.com/ko_kr",
  solutionImage:
    "../static/images/solution/analytics/splunk/splunkBackground.png",
};
const splunkITSI = {
  title: "SPLUNK ITSI",
  desc: {
    desc1: "인공지능 머신러닝을 활용한 Splunk 환경에서 실행되는",
    desc2: "<span class=impact>IT 서비스의 운영관리</span> 프리미엄 솔루션",
  },
  subDesc: "AI 기반 사고 예측, 감지 및 해결을 한 곳에서 모두 수행하는 솔루션",
  merit: {
    title: [
      "예측 및 예방",
      "데이터를 액션으로 전환",
      "확장가능한 AIOps 플랫폼",
    ],
    desc: [
      "SPLUNK ITSI는 머신러닝 알고리즘을 활용하여 IT 시스템의 성능을 예측하고, 잠재적인 문제를 사전에 식별할 수 있습니다.<br/><br/>기업은 문제 발생 전에 예방 조치를 취할 수 있으며, 서비스 중단이나 성능 저하를 최소화할 수 있습니다.",
      "SPLUNK ITSI는 수집된 데이터를 기반으로 실질적인 행동을 취할 수 있도록 지원합니다.<br/><br/>사용자에게 중요한 인사이트를 제공하고, 신속하게 의사결정을 내릴 수 있도록 도와줍니다.",
      "SPLUNK ITSI는 AIOps 기능을 갖춘 확장 가능한 플랫폼입니다.<br/><br/>IT 운영 팀이 대량의 데이터를 처리하고, 인공지능을 활용하여 문제를 자동으로 해결하거나 최적화할 수 있도록 지원합니다.<br/><br/> ITSI는 다양한 데이터 소스와 통합할 수 있어, 기업의 IT 환경에 맞춰 유연하게 확장할 수 있습니다.",
    ],
  },
  mentTitle: "Bring data to every question, decision and action.",
  mentDesc:
    "변화가 많은 IT 서비스를 연결하며 중앙화된 뷰(view)를 통해 더욱 손쉬운 IT 운영 관리가 가능합니다",
  solutionURL:
    "https://www.splunk.com/ko_kr/products/it-service-intelligence.html",
  solutionImage:
    "../static/images/solution/analytics/splunkITSI/splunkITSI.png",
};
const appdynamics = {
  title: "APP DYNAMICS",
  desc: {
    desc1: "다양한 환경의 실시간 통찰이 가능한",
    desc2: "<span class=impact>고급 애플리케이션</span> 성능 모니터링 솔루션",
  },
  subDesc: "서버, DB, End User Experience 모두 모니터링이 가능한 강력한 솔루션",
  merit: {
    title: ["통합 모니터링", "컨텍스트 가시성", "애플리케이션 비즈니스분석"],
    desc: [
      "Appdynamics는 애플리케이션, 서버, 데이터베이스 등 다양한 IT 환경을 통합적으로 모니터링할 수 있는 기능을 제공합니다.<br/><br/>전체 시스템의 성능을 실시간으로 파악하고, 문제를 신속하게 식별할 수 있습니다.",
      "Appdynamics는 애플리케이션의 성능 데이터를 수집할 뿐만 아니라, 사용자 경험과 비즈니스 성과와의 연관성을 분석합니다.<br/><br/>IT 팀은 문제의 원인을 더 잘 이해하고, 비즈니스에 미치는 영향을 평가할 수 있습니다<br/><br/>컨텍스트 가시성은 문제 해결을 위한 보다 효과적인 접근 방식을 제공합니다.",
      "Appdynamics는 애플리케이션의 성능을 비즈니스 관점에서 분석할 수 있는 기능을 제공합니다.<br/><br/>기업은 애플리케이션이 비즈니스 목표에 어떻게 기여하는지를 이해하고, 성과를 최적화할 수 있는 기회를 식별할 수 있습니다.<br/><br/>비즈니스 KPI와 애플리케이션 성능을 연결하여, 더 나은 의사결정을 지원합니다.",
    ],
  },
  mentTitle: "Now you see it",
  mentDesc: "모든 환경에서 앱을 관찰하고 보호하여 비즈니스 성과를 최적화하세요",
  solutionURL: "https://www.appdynamics.com/",
  solutionImage:
    "../static/images/solution/analytics/appdynamics/appdynamicsBackground.png",
};

const splunkES = {
  title: "SPLUNK ES",
  desc: {
    desc1: "장비에서 생성되는 머신데이터에 대한 분석을 통해",
    desc2:
      "<span class=impact>비즈니스를 보호하고 운영 통찰력</span>까지 제공하는 SIEM 솔루션",
  },
  subDesc:
    "컴플라이언스, 애플리케이션 보안, 인시던트, 고급위협 탐지, 실시간 모니터링 등 Splunk 기반 프리미엄 보안 솔루션",
  merit: {
    title: [
      "신속한 공격 탐지 및 대응",
      "상관관계 분석 및 실시간 알림",
      "위협 기반 분석",
      "통합 모니터링",
    ],
    desc: [
      "Splunk ES는 실시간 데이터 수집 및 분석 기능을 통해 보안 위협을 신속하게 탐지합니다.<br/><br/>공격이 발생하기 전에 또는 발생 직후에 즉각적으로 대응할 수 있어 피해를 최소화할 수 있습니다.",
      "Splunk ES는 다양한 데이터 소스에서 수집된 정보를 상관관계 분석하여 이상 징후를 식별합니다.<br/><br/>실시간 알림 기능이 작동하여 보안 팀이 즉각적으로 조치를 취할 수 있도록 지원합니다.",
      "Splunk ES는 최신 위협 정보를 활용하여 분석을 수행합니다.<br/><br/>현재의 위협 환경에 맞춰 방어 전략을 조정하고, 특정 위협에 대한 대응 방안을 마련할 수 있습니다.",
      "Splunk ES는 다양한 IT 환경과 보안 솔루션을 통합하여 모니터링할 수 있는 기능을 제공합니다.<br/><br/>전체 시스템의 상태를 한눈에 파악하고, 필요한 경우 신속하게 조치를 취할 수 있습니다.",
    ],
  },
  mentTitle: "Turning data into doing",
  mentDesc:
    "ES는 보안실무자에게 인프라에서 발견되는 위협에 대한 가시성을 제공하고 문제가 식별되면 짧은 시간 내에 신속하게 조사하고 해결할 수 있습니다.",
  solutionURL: "https://www.splunk.com/ko_kr/products/enterprise-security.html",
  solutionImage: "../static/images/solution/security/splunkES/splunkES.png",
};
const weblog = {
  title: "NEXGUARD 웹키퍼",
  desc: {
    desc1: "웹 공격 보안관제 모니터링을 위한",
    desc2:
      "머신러닝 모델 기반 <span class=impact>Unknown 위협 탐지</span> 보안 솔루션",
  },
  subDesc: "Splunk, AI 기반 비정상 이상징후 탐지 솔루션",
  merit: {
    title: ["유연한 확장", "미 탐지영역 탐지"],
    desc: [
      "NexGuard 웹키퍼는 소규모 환경에서 시작하여 필요에 따라 시스템을 확장할 수 있으며, 신규 솔루션 도입 형태에 비해 Splunk의 App형태로 최소한의 비용으로 도입이 가능합니다. ",
      "NexGuard 웹키퍼는 AI머신러닝을 기반으로 상용 WAF 솔루션에서 차단하지 못하는 신규 위협을 신속하게 탐지 및 대응을 수행합니다.",
    ],
  },
  mentTitle: "Efficient and Active Change",
  mentDesc:
    "NexGuard 웹키퍼 솔루션은 머신러닝을 기반으로 신규 위협체계를 확대, 더욱 촘촘한 보안관제와 미탐지 영역을 탐지영역으로 전환하여 신속한 탐지/대응 체계를 마련하는  솔루션입니다.",
  solutionURL: "",
  solutionImage: "../static/images/solution/security/weblog/hanbaekkorea.png",
};
const guradicore = {
  title: "GUARDICORE",
  desc: {
    desc1: "디지털 자산을 지키는 가장 안전한 방법",
    desc2: "<span class=impact>제로 트러스트</span> 기반 통합보안 솔루션 ",
  },
  subDesc:
    "인프라의 구성과 상관없이 유출을 신속하게 탐지하고 차단하는 글로벌 솔루션",
  merit: {
    title: [
      "AI기반 정밀 세그멘테이션",
      "실시간 및 이력 가시성",
      "광범위한 플랫폼 지원",
      "유연한 자산 레이블링",
      "다양한 보안방법",
    ],
    desc: [
      "Guardicore는 AI 추천, 랜섬웨어와 기타 일반적인 사용 사례를 위한 템플릿, 정확한 워크로드 속성 (프로세스, 사용자, 도메인 이름 등)을 사용해 몇 번의 클릭만으로 정책을 실행합니다.",
      "Guardicore는 실시간으로 네트워크 트래픽을 모니터링하고, 이력 데이터를 분석하여 보안 상태를 가시화합니다.<br/><br/>보안 팀은 현재의 위협을 신속하게 파악하고, 과거의 데이터를 통해 패턴을 분석하여 미래의 위협을 예측할 수 있습니다.",
      "Guardicore는 베어메탈 서버, 가상 머신, 컨테이너, IoT, 클라우드 인스턴스에 걸쳐 최신 및 레거시 운영 체제를 지원합니다.",
      "Guardicore는 맞춤형 레이블링 계층 구조로 강력한 컨텍스트를 추가해 가시성과 실행력을 높여주고, 오케스트레이션 툴과 설정 관리 데이터베이스를 통합해 레이블링을 자동화합니다.",
      "Guardicore는 여러 가지 보안 방법을 제공하여 다양한 위협에 대응할 수 있도록 합니다.<br/><br/>여기에는 침입 탐지, 방화벽, 세분화된 접근 제어 등이 포함되어 있어, 필요에 따라 적절한 보안 조치를 선택하고 조합할 수 있습니다.",
    ],
  },
  mentTitle: "A better way to achieve Zero Trust-segmentation",
  mentDesc:
    "Akamai Guardicore Segmentation은 네트워크 내에 제로 트러스트 원칙을 구현하는 가장 간편하고, 빠르고, 직관적인 방법을 제공합니다",
  solutionURL:
    "https://www.akamai.com/ko/products/akamai-guardicore-segmentation",
  solutionImage: "../static/images/solution/security/akamai/guardicore.png",
};
const purestorage = {
  title: "PURESTORAGE",
  desc: {
    desc1: "데이터 서비스의 새로운시대",
    desc2:
      "<span class=impact>11년 연속 최상위 리더 </span>프라이머리 스토리지 플랫폼",
  },
  subDesc: "엔터프라이즈급 올플래시 블록, 파일 및 오브젝트 스토리지",
  merit: {
    title: [
      "랜섬웨어 방지",
      "무중단 업그레이드",
      "업계에서 가장 세부적이고 완전한 데이터 절감",
      "빠른 데이터 접근",
      "높은 수준의 기술지원",
    ],
    desc: [
      "PureStorage의 SafeMode Snapshots 기능으로 백업 데이터와 메타데이터를 보호하여 관리자 크리덴셜이 유출되더라도 랜섬웨어가 데이터를 삭제, 수정 또는 암호화 할수 없도록 합니다.",
      "PureStorage의 Evergreen 프로그램으로 스토리지의 기능을 무중단으로 향샹시킵니다.",
      "PureStorage의 뛰어난 데이터 절감 기법으로 실제 용량보다 더 많은 용량을 사용할 수 있게 합니다.<br/>Hyper-V와 같은 가상 서버 4~6:1 / 데이터베이스 환경 2~4:1 / 가상 데스크탑 환경 5~10:1",
      "PureStorage는 전용 명령어와 차별화된 접근 방식으로 데이터에 빠르게 접근할 수 있습니다.",
      "PureStorage의 Pure1 프로그램으로 인공지능 기반 관리와 풀스택 분석으로 더 높은 수준의 예측적 기술지원을 제공합니다.",
    ],
  },
  mentTitle: "Accelerate your data success",
  mentDesc:
    "PureStorage는 보다 간편하고 경제적이며 우수한 스토리지 경험들을 선사합니다.",
  solutionURL: "https://www.purestorage.com/kr/",
  solutionImage: "../static/images/solution/infra/pure/pureBackground.png",
};
const nexguardILMS = {
  title: "NEXGUARD ILMS",
  desc: {
    desc1: "기업의 인적자산 유출을 방지하는 ",
    desc2:
      "Splunk 기반의 <span class=impact>정보 유출 이상 징후</span> 모니터링 솔루션",
  },
  subDesc: "실시간 모니터링, 정보유출 방지, 이상징후 분석 자체개발 보안 솔루션",
  merit: {
    title: [
      "데이터 연동",
      "대시보드 구성",
      "높은 수준의 기술지원",
    ],
    desc: [
      "NexGuard ILMS는 Splunk 기반으로 장비의 로그나 데이터를 형식에 구애 받지 않고 연동이 가능하며 빠른 검색과 분석이 가능합니다.<br/><br/>Nexguard ILMS는 다양한 산업에 적용 가능하여, 각 기업의 특성에 맞춘 맞춤형 분석을 제공합니다. 고객의 요구에 따라 유연하게 대응하며, 실시간으로 데이터를 업데이트하고 분석 결과를 반영함으로써 항상 최신 정보를 제공합니다.",
      "NexGuard ILMS는 고객에 니즈에 맞춘 대시보드 구성으로 직원의 이상징후나 정보유출 정보를 빠르게 파악하여 기업의 인적 및 물적 자산을 보호합니다.<br/><br/>Nexguard ILMS는 직관적인 대시보드와 시각적 데이터 표현을 통해, 복잡한 데이터 분석 결과를 고객이 쉽게 이해하고 판단할 수 있도록 돕습니다. 고객은 단순히 수치와 통계에 의존하는 것이 아니라, 데이터 분석 전문가들이 제공한 인사이트를 바탕으로 더 나은 의사결정을 내릴 수 있습니다.",
      "NexGuard ILMS는 한백코리아에서 자체 개발된 솔루션으로, 기술 지원에 있어서도 신속하고 효과적인 서비스를 제공합니다. 한백코리아는 고객의 요구를 기다리는 것이 아니라, 적극적으로 고객과 소통하며 데이터 분석 전문가의 조언을 통해 보다 효과적으로 기업의 정보 유출을 방지할 수 있습니다.",
    ],
  },
  mentTitle: "Protect everything",
  mentDesc:
    "NexGuard ILMS는 한백코리아의 자체솔루션으로 뛰어난 전문가의 데이터 분석 및 화면 구성으로 기업의 정보를 효과적으로 보호합니다.",
  solutionURL: "",
  solutionImage: "../static/images/solution/security/weblog/hanbaekkorea.png",
};
const nexguardTI = {
  title: "NEXGUARD TI",
  desc: {
    desc1: "기업의 안전한 연결을 위한",
    desc2:
      "Splunk 기반의 <span class=impact>Threat Intelligence</span> 종합 분석 솔루션",
  },
  subDesc: "TI 종합 검색, 시각화, 정보 분석 보안 솔루션",
  merit: {
    title: [
      "확장성",
      "검증된 정보",
      "시각화"
    ],
    desc: [
      "NexGuard TI는 Splunk 기반으로 이미 기업에서 쓰고있는 TI 솔루션을 포함하여 유연하게 확장이 가능합니다.",
      "NexGuard TI는 여러 Global Solution TI 제품을 비교하여 보다 검증된 통합 TI정보를 제공합니다.",
      "NexGuard TI는 Splunk를 기반으로 단순한 정보 확인을 넘어 대시보드를 통해 담당자들이 보다 쉽게 정보를 시각적으로 확인할 수 있는 강점을 가지고 있습니다",
    ],
  },
  mentTitle: "Verified and Confident Information",
  mentDesc:
    "NexGuard TI는 여러 글로벌 솔루션 TI의 검증된 정보로 기업을 향한 위협으로 부터 보호합니다.",
  solutionURL: "",
  solutionImage: "../static/images/solution/security/weblog/hanbaekkorea.png",
};
const normal = {
  title: "",
  desc: {
    desc1: "",
    desc2: "",
  },
  subDesc: "",
  merit: {
    title: [""],
    desc: [""],
  },
  mentTitle: "",
  mentDesc: "",
  solutionURL: "",
  solutionImage: "",
};
