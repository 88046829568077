import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Lang } from "~/types/language";
import { MOBILE_BREAK } from "../../constants/app";

export const uiSlice = createSlice({
  name: "UI",
  initialState: {
    isMobile: window.innerWidth < MOBILE_BREAK,
    activateLnb: false,
    language: window.localStorage.getItem("site_language"),
  },
  reducers: {
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setActivateLnb: (state, action: PayloadAction<boolean>) => {
      state.activateLnb = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Lang>) => {
      window.localStorage.setItem("site_language", action.payload);
      state.language = action.payload;
    },
  },
});

export default uiSlice;
