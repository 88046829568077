export const PATH = {
  ROOT: "/",
  COMPANY: "/company",
  SECURITY : "/security",
  ANALYTICS : "/analytics",
  INFRA : "/infra",
  SPLUNK:"/analytics/splunk",
  SPLUNKITSI:"/analytics/splunkITSI",
  APPDYNAMICS:"/analytics/appdynamics",
  SPLUNKES:"/security/splunkES",
  NEXGUARDILMS:"/security/NexGuardILMS",
  NEXGUARDTI:"/security/NexGuardTI",
  WEBLOG:"/security/NexGuardWebkeeper",
  GUARDICORE:"/security/guradicore",
  PURESTORAGE:"/infra/purestorage"
  // WINTERGROUND : "/wave/winterGround"
} as const;
