/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from "react";

import CompanySection1 from "../container/company/CompanySection1";
import CompanySection2 from "../container/company/CompanySection2";
import CompanySection3 from "../container/company/CompanySection3";
import FullVideoSection from "../components/main/FullVideoSection";
import Layout from "../layout/Layout";
import MetaTag from "../components/main/MetaTag";
import TopButton from "../components/TopButton";

// eslint-disable-next-line react/function-component-definition
const Company: React.FC = () => {
  return (
    <>
      <MetaTag title="company" />
      <TopButton/>
      <Layout>
        <FullVideoSection
          video="static/images/company/companyVideo.mp4"
          imageWeb=""
          imageMobile=""
          title="성공적인 IT 스토리를 만드는 한백코리아"
          content="Building IT Success Stories with Hanbaek Korea"
          section="company"
        />
        {/* <CompanySection1 /> */}
        <CompanySection2 />
        <CompanySection3 />
      </Layout>
    </>
  );
};

export default Company;
