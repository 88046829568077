/* eslint-disable no-else-return */
// eslint-disable-next-line func-names
const isEmpty = function (
  value:
    | string
    | null
    | undefined
    | number
    | string[]
    | number[]
    | Record<string, unknown>
) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    (typeof value === "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

export { isEmpty };
