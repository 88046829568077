import { createSelector } from "@reduxjs/toolkit";
import { Lang, langUnion } from "../../types/language";
import { RootState } from "../../store";
import en from "../../i18n/en.json";
import ko from "../../i18n/ko.json";

const selectUi = (state: RootState) => state.ui;

export const selectMobile = createSelector(selectUi, (ui) => ui.isMobile);
export const selectActivateLnb = createSelector(
  selectUi,
  (ui) => ui.activateLnb
);

export const selectI18n = createSelector(
  ({ ui }: RootState) => ui.language,
  (lang) => getI18n(lang === Lang.ko || lang === Lang.en ? lang : Lang.en)
);

const langMap: { [key in langUnion]: typeof en | typeof ko } = {
  en,
  ko,
};

export const getI18n = (lang: langUnion) => {
  return langMap[lang] as typeof en;
};
