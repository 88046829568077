/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import MainStyle from "../../style/MainStyle";
import useIntersectionObsever from "../../hooks/useIntserctionObserver";

export default function CompanySection2() {
  const refs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const isInViewport = refs.map((ref) => useIntersectionObsever(ref));
  const [visibleContainers, setVisibleContainers] = useState([
    false,
    false,
    false,
  ]);
  useEffect(() => {
    if (isInViewport[1]) { // 두 번째 ref가 뷰포트에 들어왔을 때만 실행
      const timerIds = visibleContainers.map((_, index) =>
        setTimeout(() => {
          setVisibleContainers((prev) => {
            const newVisible = [...prev];
            newVisible[index] = true; // 해당 인덱스만 true로 설정
            return newVisible;
          });
        }, index * 300) // 각 요소마다 300ms 간격으로 딜레이
      );

      // 클린업 함수
      return () => timerIds.forEach((timerId) => clearTimeout(timerId));
    }
  }, [isInViewport[1]]); // isInViewport[1]의 상태를 의존성 배열에 추가
  return (
    <MainStyle.Section5>
      <>
        <div className={`firstContainer ${isInViewport[0] ? "fade-in" : ""}`} ref={refs[0]}>
          <p className="value">ENTERPRISE VALUES</p>
          <p className="mainContent">
            우리회사는 윤리적 판단과 최신 AI 기술을 바탕으로{" "}
            <span className="impact">고객 중심</span>의 미래를 만들어갑니다.
          </p>
        </div>
        <div className="container" ref={refs[1]}>
          {[
            {
              src: "static/images/company/first.svg",
              subText: "AIOps Leader",
              hash: "#선도 기업",
            },
            {
              src: "static/images/company/growth.svg",
              subText: "Proper Management",
              hash: "#정도 경영",
            },
            {
              src: "static/images/company/customer.svg",
              subText: "Customer Priority",
              hash: "#고객 최우선",
            },
          ].map((item, index) => (
            <div
              key={index}
              className={`valueContainer ${isInViewport[1] && visibleContainers[index] ? "visible" : ""}`}
            >
              <img
                className="test"
                src={item.src}
                
                alt={item.subText}
              />
              <p className="subText">{item.subText}</p>
              <p className="hash">{item.hash}</p>
            </div>
          ))}
        </div>
      </>
    </MainStyle.Section5>
  );
}
