export const COLOR = {
  POINT: "#00c8ff",
  BLACK: "#4e4e4e",
  WHITE: "#FFFFFF",

  SPLUNK: "#F9A126",
  SPLUNKITSI: "#E20883",
  APPDYNAMICS: "#617283",
  SPLUNKES: "#5CC05C",
  WEBLOG: "#00a7ff",
  NEXGUARDILMS: "#00a7ff",
  NEXGUARDTI: "#00a7ff",
  GUARDICORE: "#6E1EE5",
  PURESTORAGE: "#F05322",
  
} as const;
