/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MOBILE_CSS, HEADER_SIZE } from "../constants/app";
import { COLOR } from "../constants/color";
import BoxStyle from "./BoxStyle";

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  min-height: ${HEADER_SIZE}px;
  height: 9vh;
  width: 100%;
  z-index: 2;
  transition: transform 0.3s;

  &.scroll-down {
    background-color: rgba(0, 0, 0, 0.15);
    .menu {
      &:hover {
        background: none;
      }
    }
    .etc-menu {
      a {
        color: ${COLOR.BLACK};
        border-color: ${COLOR.BLACK};
        z-index: 2;
      }
      > .lang > ul > .main > a {
        color: ${COLOR.BLACK};
        border-color: ${COLOR.BLACK} !important;
        z-index: 2;
      }
    }
    .wp-btn {
      color: ${COLOR.POINT};
      border-color: ${COLOR.POINT};
      span {
        background-image: url(${"../static/images/main/hanbeakLogoMain.png"});
      }
    }
    .wp-btn2 {
      color: ${COLOR.POINT};
      border-color: ${COLOR.POINT};
      span {
        background-image: url(${"../static/images/main/hanbeakLogoMain.png"});
      }
    }
    .wp-btn3 {
      color: ${COLOR.POINT};
      border-color: ${COLOR.POINT};
    }

    .header-menu {
      .text {
        color: ${COLOR.BLACK};
      }
      .bar,
      .bar::before,
      .bar::after {
        background-color: #fff;
      }
    }
  }

  .logo {
    position: absolute;
    bottom: 1.7vh;
    min-width: 11vw;
    min-height: 6vh;
    width: 213px;
    height: 53px;
    left: 44px;
    margin: 0;
    text-indent: -9999px;
    background-image: url("../static/images/main/hanbeakLogoMain.svg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    z-index: 4;
  }
  .logo:hover {
    pointer: cursor;
  }
  .nav {
    position: absolute;
    right: 25px;
    top: 6px;

    display: flex;
    z-index: 1;
  }
  .menu {
    display: inline-flex;
    align-items: center;
    font-family: pretendard;
    font-size: 2.393vmin;

    white-space: nowrap;
    margin-left: 30px;
    &:first-of-type {
      margin-left: 0;
    }
    &.hover {
      .sub-menu {
        display: block;
      }
    }
    a {
      display: inline-flex;
      text-decoration: none;
      padding: 3.3vh 1vw;
    }
    > a {
      font-weight: 500;
      color: #fff;
      &:hover,
      &.active,
      &:focus {
        color: #00c8ff;
      }
    }
  }

  .sub-menu {
    position: absolute;
    top: ${HEADER_SIZE}px;
    white-space: nowrap;
    z-index: 2;
    display: none;
    &:after {
      content: "";
      position: fixed;
      width: 100vw;
      height: 30px;
      top: ${HEADER_SIZE}px;
      left: 0;
      height: 80px;
      background: #f6f6f6;
      ${BoxStyle({ shadowType: [0, 3, 6, 0], shadowValue: 154 })};
      z-index: 0;
    }
  }
  .sub-menu-link {
    position: relative;
    display: inline-flex;
    opacity: 0.85;
    margin-left: 66px;
    z-index: 1;
    &:first-of-type {
      margin-left: 0;
    }
    a {
      position: relative;
      min-width: 76px;
      padding: 27px 0 29px;
      font-size: 17px;
      color: ${COLOR.BLACK};
      font-weight: 500;
      line-height: 1.47;
      justify-content: center;
      &.active,
      &:hover,
      &:focus {
        color: ${COLOR.POINT};
        &:before {
          content: "";
          position: absolute;
          width: 76px;
          height: 6px;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          background-color: ${COLOR.POINT};
        }
      }
    }
  }
  .detail-menu-box {
    position: absolute;
    top: 50px;
    left: -30px;
    height: 0;
    width: 220px;
    padding: 0 30px;
    transition: height 0.3s;
    overflow: hidden;
    background-color: #fff;
    z-index: 2;
    &.active {
      height: 252px;
    }
    .detail-menu:first-of-type {
      a {
        border-top: 0;
      }
    }
    a {
      display: block;
      font-size: 14px;
      color: #535353;
      padding: 24px 0px;
      font-weight: 500;
      border-top: 1px solid #cecece;
    }
  }
  .detail-menu {
    &.active {
      a {
        font-weight: 700;
      }
    }
    &.disabled {
      a {
        color: #c8c8c8;
        font-weight: 400;
      }
    }
  }
  .wp-btn {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 44px;
    right: 140px;
    border: 1px solid #fff;
    color: #fff;
    height: 38px;
    padding: 0 25px;
    border-radius: 25px;
    span {
      background-image: url(${"static/images/main/hanbeakLogoMain.png"});
      background-repeat: no-repeat;
      background-size: 18px auto;
      padding-left: 26px;
    }
  }
  .wp-btn:hover {
    border: 1px solid #002c83;
    background-color: #002c83;
    color: #fff;
  }
  .wp-btn2 {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 44px;
    right: 15%;
    border: 1px solid #fff;
    color: #fff;
    height: 38px;
    padding: 0 25px;
    border-radius: 25px;
    span {
      background-image: url(${"static/images/main/hanbeakLogoMain.png"});
      background-repeat: no-repeat;
      background-size: 18px auto;
      padding-left: 26px;
    }
  }
  .wp-btn2:hover {
    border: 1px solid #002c83;
    background-color: #002c83;
    color: #fff;
  }
  .wp-btn3 {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    top: 44px;
    right: 70px;

    color: #fff;
    height: 38px;
    padding: 0 15px;
  }

  .header-menu {
    position: absolute;
    width: 30vw;
    height: 30px;
    top: 29px;
    right: 30px;
    display: none;
    button {
      position: relative;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
    }
    .text {
      opacity: 0;
      color: #fff;
      text-indent: -9999px;
    }
    .bar {
      text-align: right;
      top: 14px;
      &::before {
        top: -7px;
      }
      &::after {
        bottom: -7px;
      }
      &::before,
      &::after {
        content: "";
      }
    }
    .bar,
    .bar::before,
    .bar::after {
      display: block;
      height: 2px;
      position: absolute;
      width: 30px;
      background-color: #fff;
    }
  }
  .etc-menu {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 140px;
    a {
      display: inline-flex;
      font-size: 13px;
      color: #fff;
      padding: 0 7px;
      border-left: 1px solid #fff;
      &:first-of-type {
        border-left: 0;
      }
      &.lang {
        position: relative;
        padding-right: 20px;
        &:after {
          content: "";
          position: absolute;
          top: 2px;
          right: 0;
          width: 6px;
          height: 6px;
          border-width: 0 0 1px 1px;
          border-style: solid;
          border-color: #fff;
          transform: rotate(-45deg);
        }
      }
    }
  }
  @media (max-width: 1900px) {
    .wp-btn2 {
      right: 17%;
    }
  }
  @media (max-width: 1450px) {
    .etc-menu {
      right: 160px;
      z-index: 2;
    }
    .wp-btn {
      right: 20px;
    }
    .wp-btn2 {
      right: 17%;
    }
    .wp-btn3 {
      right: 20px;
    }
  }
  @media (max-width: 1200px) {
    // .header-menu {
    //   left: 20px;
    // }

    .etc-menu {
      z-index: 2;
      display: none;
    }
    .wp-btn2 {
      right: 17%;
    }
  }
  ${MOBILE_CSS} {
    height: 54px;
    .logo {
      top: 30px;
      left: 105px;
      transform: translateX(-50%);
      width: 150px;
    }
    .nav {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      background-color: rgba(9, 9, 35, 0.97);
      mix-blend-mode: multiply;
      transform: translateX(-100%);
      padding: 20px;
      overflow: auto;
      transition: 0.3s transform;
      &.active {
        transform: translateX(0%);
        justify-content: center;
      }
    }
    .menu {
      display: flex;
      margin-left: 0;
      height: 100vh;
      justify-content: center;

      height: 15vh;
      &.active:after {
        display: none;
      }
      &.hover,
      &:hover {
        background: none;
      }
      > a {
        font-size: 7vmin;
        display: block;
        position: relative;
        font-weight: bold;
        padding: 20px 0;
        text-align: center;
        color: white;
        // &:after {
        //   position: absolute;
        //   right: 0;
        //   top: 0%;
        //   transform: translateY(-50%);
        //   content: "";
        //   background-image: url("${"static/images/main/hanbeakLogoMain.png"}");
        //   width: 24px;
        //   height: 24px;
        //   background-size: 100% auto;
        //   background-repeat: no-repeat;
        // }
        &:hover,
        &.active,
        &:focus {
          color: ${COLOR.POINT};
        }
      }
    }
    .sub-menu {
      position: static;
      padding: 0;
      &:after {
        display: none;
      }
    }
    .sub-menu-link {
      display: block;
      margin-left: 0;
      a {
        display: block;
        font-size: 15px;
        padding: 20px 0;
        border-bottom: 1px solid #cecece;
        &:hover,
        &.active,
        &:focus {
          &:before {
            display: none;
          }
        }
      }
    }
    .detail-menu-box {
      position: static;
      height: auto;
      width: auto;
      padding: 0;
    }
    .detail-menu {
      a {
        font-size: 15px;
        color: #4d4d4d;
        font-weight: 400;
        padding: 20px 0;
      }
    }
    .nav.active + .header-menu {
      z-index: 3;
      .text {
        display: none;
      }
      .bar,
      .bar::before,
      .bar::after {
        background-color: #fff;
        top: 0;
      }
      .bar {
        top: 12px;
        background: rgba(255, 255, 255, 0);
      }
      .bar:before {
        transform: rotate(45deg);
      }
      .bar:after {
        transform: rotate(-45deg);
      }
    }
    .header-menu {
      display: flex;
      justify-content: flex-end; /* 오른쪽 정렬 */
      position: relative; /* 필요한 경우 position 설정 */
      right: 55px;
      width: 100vw;
      button {
        background-color: unset;
        border: unset;
        display: flex; /* 버튼 내부도 flex로 설정 */
        align-items: center; /* 수직 중앙 정렬 */
      }

      .text {
        opacity: 1;
        text-indent: 0;
        position: absolute;
        font-style: normal;
        white-space: nowrap;
        top: 50%;
        transform: translateY(-50%);

        font-size: 12px;
      }
    }
    .wp-btn {
      top: 7px;
      right: 0;
      border: none;
    }
    .wp-btn2 {
      top: 40px;
      right: 0;
      border: none;
    }
    .wp-btn3 {
      font-size: 30px;
      top: 12px;
      right: 0;
      border: none;
    }
    .etc-menu {
      z-index: 2;
      display: none;
    }
  }
`;

const Footer = styled.div<{ full?: boolean; black?: boolean }>`
  font-family: pretendard;
  position: relative;
  width: ${(props) => (props.full ? "100%" : "auto")};
  color: ${(props) => (props.black ? COLOR.BLACK : "#ffff")};
  display: flex;
  flex-direction: column;
  padding-left: 9vw;
  // align-items: center;
  height: 177px;
  text-align: center;
  background: black;
  justify-content: center;
  ${MOBILE_CSS} {
    padding-left: unset;
    
    text-align: left;
    align-items: inherit;
    height: 30vh;
    min-height: 270px;
  }
  .footer_content {
    display: flex;
    align-items: center;
    ${MOBILE_CSS} {
        flex-direction: column;
      justify-content: center;
    }
  }
  .footer_letter {
    margin-left: 59px;
    text-align: left;
    ${MOBILE_CSS} {
      text-align: center;
      margin-left: unset;
      line-height: 1.35;
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #00c8ff;
      }
    }
  }
  .footer_logo {
    position: relative;
    width: 10vw;
    height: 43px;
    img {
      width: 100%;
    }
    ${MOBILE_CSS} {
      width: 36vw;
      position: static;
    }
  }
  p {
    margin: 0;
  }
  .addres {
    font-size: 1.976vmin;
    margin-bottom: 8px;
    ${MOBILE_CSS} {
    margin-top: 15px;
    margin-bottom: 10px;
      font-size: 4vmin;
    }
  }
  .copy {
    font-size: 1.31vmin;
    color: #a8a8a8;
    ${MOBILE_CSS}{
    font-size : 3.3vmin;}
  }
`;

export default {
  Header,
  Footer,
};
