import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MOBILE_CSS, CONTENT_SIZE } from "src/constants/app";

const FullSection = styled.div`

  font-family: pretendard;
  display: flex;
  position: relative;
  flex: 1;
  height: 120vh;
  width: 100%;
  .main-video {
    opacity: 0.8;
  }
  svg {
    animation: fade-in 1.5s linear;
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   height: 120vh;
  //   background-color: black;
  //     opacity: 0;
  // }
  // .mainLogo{
  //     position: absolute;
  //         top:19vh;
  //               transform: translateX(-100px); /* 왼쪽에서 시작 */
  //     animation: slideIn 2s forwards; /* 애니메이션 이름과 지속 시간 */
  // }
  .full-content {
    position: relative;
    width: 100%;
    z-index: 1;
    .inner {
      display: flex;
      position: relative;
      // max-width: ${CONTENT_SIZE}px;
      min-height: 100%;
      align-items: baseline;
      margin: 0 9vw;
      padding: 0 20px;
      box-sizing: content-box;
      justify-content: center;
    }
  }
  .main-section-box {
    position: absolute;
    bottom: 27vh;
    color: #fff;
    .section-title {
      font-size: 65px;
      line-height: 1.21;
      text-align: center;
      //letter-spacing: -0.2rem;
      margin-bottom: 40px;
      font-weight: bold;
      white-space: pre-wrap;
      opacity: 0; /* 초기 투명도 */
      transform: translateX(-100px); /* 왼쪽에서 시작 */
      animation: slideIn 2s forwards; /* 애니메이션 이름과 지속 시간 */
    }
    .section-content-resizing {
      font-size: 27px;
      margin-bottom: 40px;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      letter-spacing: normal;
      opacity: 0; /* 초기 투명도 */
      transform: translateX(-100px); /* 왼쪽에서 시작 */
      animation: slideIn 3s forwards; /* 애니메이션 이름과 지속 시간 */
    }
  }
  @keyframes slideIn {
    to {
      opacity: 1; /* 최종 투명도 */
      transform: translateX(0); /* 최종 위치 */
    }
  }
  .full-page-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-size: cover;
    background-position: center;
    --fill-layer-background-media-position: fixed;
    video {
      max-width: inherit;
      position: fixed;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(0%);
      transform: translateX(-50%) translateY(0%);
      min-width: 100%;
      min-height: 120vh;
      max-width: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .section-title {
    width: 100%;
  }
  .on {
    filter: brightness(80%);
  }

  ${MOBILE_CSS} {
    .main-section-box {
      bottom: 152px;
      .section-title {
        font-size: 31px;
        line-height: 1.29;
        margin-bottom: 10px;
        font-weight: 700;
      }
      .section-content {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 56px;
      }
      .section-content-resizing {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 56px;
      }
      .sub-comment {
        font-size: 20px;
        line-height: 1.23;
        //letter-spacing: -0.2rem;
        margin-top: -25px;
        margin-bottom: 15px;
        font-weight: bold;
        white-space: pre-wrap;
      }
    }
    .section-btn {
      a {
        min-width: 140px;
        font-size: 12px;
        line-height: 18px;
        height: 35px;
        padding: 0 15px 0 20px;
      }
    }
    &.fp-auto-height {
      .fp-scroller {
        height: auto;
      }
      .inner {
        min-height: 100vh;
      }
    }
  }
`;
const FullSubSection = styled.div`
  padding-top: 147px;
  font-family: pretendard;
  display: flex;
  position: relative;
  flex: 1;
  height: 834px;
  width: 100%;
  background-color: rgb(9, 9, 35);
  ${MOBILE_CSS} {
      height: 500px;
    }
  &:after {
    top : 146px;
    content: "";
    position: absolute;
    background-color: black;
    width: 100%;
    height: 835px;
    opacity: 0.5;
    
    ${MOBILE_CSS} {
      height: 500px;
    }
  }
  .inner {
    position: absolute;
    color: black;
    z-index: 1;
    color: white;
    font-family: pretendard;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: 87px;
  }
  .main-section-box {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .section-title {
    text-align: center;
    font-size: 7.135vmin;
    font-weight: 800;
    line-height: 1.21;
    width: 100%;
    margin-bottom: 58px;
    text-shadow: 2px 2px 5px black;
    -webkit-appearance: none;
  }
  .section-content-resizing {
    font-size: 2vmin;
    font-weight: 600;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }

  .full-page-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;

    video {
      -webkit-transform: translateX(0%) translateY(0%);
      transform: translateX(0%) translateY(0%);
      width: 100%;
      height: 834px;
      overflow: hidden;
      object-fit: cover;
      ${MOBILE_CSS} {
      height: 500px;
    }
    }
  }
`;
const FullCompanySection = styled.div`
  font-family: pretendard;
  display: flex;
  position: relative;
  flex: 1;
  height: 100vh;
  width: 100%;
  .main-video {
    opacity: 0.8;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
  }
  .full-content {
    position: relative;
    width: 100%;
    z-index: 1;
    .inner {
      display: flex;
      position: relative;
      // max-width: ${CONTENT_SIZE}px;
      min-height: 100%;
      align-items: baseline;
      margin: 0 9vw;
      padding: 0 20px;
      box-sizing: content-box;
    }
  }
  .main-section-box {
    position: absolute;
    bottom: 15vh;
    color: #fff;
    .section-title {
      font-size: 7.135vmin;
      line-height: 1.21;
      text-shadow: 2px 2px 5px #000;
      //letter-spacing: -0.2rem;
      margin-bottom: 40px;
      font-weight: bold;
      white-space: pre-wrap;
      opacity: 0; /* 초기 투명도 */
      transform: translateX(-100px); /* 왼쪽에서 시작 */
      animation: slideIn 2s forwards; /* 애니메이션 이름과 지속 시간 */
    }
    .section-content-resizing {
      font-size: 2.964vmin;
      margin-bottom: 40px;
      font-weight: 600;
      line-height: 1.5;
      text-shadow: 2px 2px 5px #000;
      letter-spacing: normal;
      opacity: 0; /* 초기 투명도 */
      transform: translateX(-100px); /* 왼쪽에서 시작 */
      animation: slideIn 3s forwards; /* 애니메이션 이름과 지속 시간 */
      ${MOBILE_CSS}{
      font-size : 4.5vmin;
      }
    }
  }
  @keyframes slideIn {
    to {
      opacity: 1; /* 최종 투명도 */
      transform: translateX(0); /* 최종 위치 */
    }
  }
  .full-page-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    --fill-layer-background-media-position: fixed;
    video {
      max-width: inherit;
      position: fixed;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(0%);
      transform: translateX(-50%) translateY(0%);
      min-width: 100%;
      min-height: 120vh;
      max-width: 100%;
      width: auto;
      height: auto;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .section-title {
    width: 100%;
    
  }
  .on {
    filter: brightness(80%);
  }

  ${MOBILE_CSS} {
    .main-section-box {
      bottom: 152px;
      .section-title {
        line-height: 1.29;
        margin-bottom: 10px;
        font-weight: 700;
        text-align : center;
      }
      .section-content {
      
      
        font-weight: 400;
        margin-bottom: 56px;
      }
      .section-content-resizing {
      
        text-align :center;
        font-weight: 400;
        margin-bottom: 56px;
      }
      .sub-comment {
        font-size: 20px;
        line-height: 1.23;
        //letter-spacing: -0.2rem;
        margin-top: -25px;
        margin-bottom: 15px;
        font-weight: bold;
        white-space: pre-wrap;
      }
    }
    .section-btn {
      a {
        min-width: 140px;
        font-size: 12px;
        line-height: 18px;
        height: 35px;
        padding: 0 15px 0 20px;
      }
    }
    &.fp-auto-height {
      .fp-scroller {
        height: auto;
      }
      .inner {
        min-height: 100vh;
      }
    }
  }
`;
const Section1 = styled.div`
  position: relative;
  width: 100%;
  background-color: rgb(9, 9, 35);
  padding-top: 13vh;
  color: white;
  p {
    font-family: pretendard;
    margin: 0;
    text-align: center;
  }
  p.aboutus {
    font-size: 2vmin;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 50px;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }
  .textArea {
    padding-bottom: 50px;
  }
  p.mainContent {
    font-size: 6.586vmin;
    font-weight: 600;
    ${MOBILE_CSS} {
      font-size: 5.8vmin;
    }
  }
  span.impact {
    color: #00a7ff;
    text-shadow: 2px 2px 5px #000;
  }
  .firstContainer {
    opacity: 0;
  }
  .fade-in {
    animation: fade-in 1.5s linear 0.5s forwards; /* 'forwards' 추가 */
  }

  @keyframes slideIn {
    to {
      opacity: 1; /* 최종 투명도 */
      transform: translateX(0); /* 최종 위치 */
    }
  }
  .section1_Logo {
    width: 100%;
    text-align: center;
    padding-bottom: 51px;
  }
  .line {
    width: 50%;
    height: 37vh;
    border-right: 2px solid #fff;
    ${MOBILE_CSS} {
      height: 220px;
    }
  }
  .downArraw {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  svg {
    width: 50%;
    font-size: 2vmin;
    text-align: center;
    padding-left: 2px;
  }

  .container {
    overflow: hidden; /* 내용이 넘치지 않도록 숨김 */
    clip-path: inset(0 0 100% 0); /* 초기 상태: 아래쪽이 가려짐 */
    transition:
      clip-path 2s ease-out 1s,
      opacity 0.5s ease-out 1s; /* 애니메이션 효과 */
    opacity: 0; /* 초기 상태: 투명 */
    display: flex;
    img {
      width: 100%;
      height: 6vh;
      ${MOBILE_CSS} {
        height: 32px;
      }
    }
  }
  .slide-down {
    clip-path: inset(0 0 0 0); /* 원래 상태로 복원 */
    opacity: 1; /* 완전히 보이도록 설정 */
  }
  ${MOBILE_CSS} {
  }
`;
const Section2 = styled.div`
  position: relative;
  width: 100%;
  background-color: rgb(9, 9, 35);
  padding-top: 83px;
  color: white;
  p {
    font-family: pretendard;
    margin: 0;
    text-align: center;
  }
  p.ourservices {
    font-size: 2vmin;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 50px;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }
  .gridArea {
    font-family: pretendard;
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: center;
    grid-column-gap: 1.1vw;

    .inner {
      bottom: 0;
      margin-left: 1.7vw;
      margin-bottom: 3vh;
      position: absolute;
      z-index: 1;
      width: 25.3vw;
      a {
        text-decoration: none;
        color: white;
        font-size: 2.525vmin;
        font-weight: 500;
        letter-spacing: 0.24px;
        ${MOBILE_CSS} {
          font-size: 4.625vmin;
        }
      }
      .title {
        font-size: 5.269vmin;
        font-weight: 600;
        margin-bottom: 25px;
        text-shadow: 2px 2px 5px #000;
        ${MOBILE_CSS} {
          font-size: 9.25vmin;
        }
      }
      .desc {
        height: 13.5vh;
        margin-top: 44px;
        font-size: 1.866vmin;
        font-weight: 600;
        width: 75%;
        word-break: keep-all;
        line-height: 1.34;
        ${MOBILE_CSS} {
          font-size: 3.532vmin;
          width: 90%;
          text-shadow: 2px 2px 5px #000;
        }
      }
      ${MOBILE_CSS} {
        margin-left: 4.7vw;
        width: auto;
        margin-bottom: 10vh;
      }
    }
  }
  .grid {
    width: 27vw;
    height: 74vh;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden; /* 내용이 넘치지 않도록 숨김 */
    position: relative; /* 자식 요소의 위치를 기준으로 설정 */
  }
  .grid::after {
    position: absolute;
    width: 27vw;
    height: 74vh;
    content: "";
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
  }

  a {
    text-shadow: 2px 2px 5px #000;
  }
  .background {
    position: absolute; /* 부모 요소에 상대적으로 위치 설정 */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover; /* 초기 배경 이미지 크기 */
    transition: transform 3s ease; /* 배경 이미지 크기 변화 애니메이션 */
    z-index: 0; /* 배경이 내용 뒤에 오도록 설정 */
  }
  .grid.hovered .background {
    transition: transform 6s ease; /* 배경 크기 변화 애니메이션 */
    transform: scale(1.3); /* hover 시 배경 이미지 확대 */
  }

  ${MOBILE_CSS} {
    .swiper {
      height: 65vh;
      width: 100vw;
      content: "";
    }
  }
`;
const Section3 = styled.div`
  position: relative;
  width: 100%;
  background-color: rgb(9, 9, 35);
  padding-top: 185px;
  color: white;
  .content {
    position: relative;
    padding-left: 8vw;
  }
  p {
    font-family: pretendard;
    margin: 0;
  }
  p.inProgress {
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
  }
  p.title {
    margin-top: 72px;
    font-size: 40px;
    font-weight: 600;
  }
  p.desc {
    margin-top: 57px;
    font-size: 18px;
    font-weight: 600;
  }
  .gridArea {
    margin-top: 45px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-right: 8.6vw;
    grid-column-gap: 5vw;
  }
  .grid {
  }
  p.number {
    color: #00a7ff;
    font-size: 72px;
    font-weight: bold;
    letter-spacing: -1.8px;
    margin-bottom: 23px;
  }
  p.text {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
  }
`;
const Section4 = styled.div`
  margin-top: -1px;
  position: relative;
  width: 100%;
  background-color: rgb(9, 9, 35);
  padding-top: 150px;
  padding-bottom: 250px;
  color: white;
  .content {
    position: relative;
    text-align: center;
  }
  p {
    font-family: pretendard;
    margin: 0;
  }
  .impact {
    color: #00a7ff;
    text-shadow: 2px 2px 5px #000;
  }
  p.inProgress {
    font-size: 2vmin;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 50px;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }
  p.title {
    margin-top: 72px;
    font-size: 4.391vmin;
    font-weight: 600;
    ${MOBILE_CSS} {
      font-size: 5.8vmin;
      width: 90%;
    }
  }
  p.desc {
    margin-top: 20px;
    font-size: 2.3vmin;
    font-weight: 600;
    ${MOBILE_CSS} {
      margin-top: 20px;
      font-size: 4vmin;
      line-height: 1.4;
    }
  }
  @keyframes slideIn {
    to {
      opacity: 1; /* 최종 투명도 */
      transform: translateX(0); /* 최종 위치 */
    }
  }

  .content {
    width: 100%;
    opacity: 0; /* 초기 상태 */
    ${MOBILE_CSS} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .fade-in {
    animation: fade-in 1.5s linear 0.7s forwards; /* 'forwards' 추가 */
  }
`;
const Section5 = styled.div`
  background-color: rgb(9, 9, 35);
  position: relative;

  padding-top: 200px;
  p {
    font-family: pretendard;
    margin: 0;
    text-align: center;
    color: #fff;
  }
  .value {
    font-size: 2vmin;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 50px;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }
  .textArea {
    padding-bottom: 50px;
  }
  p.mainContent {
    font-size: 3.293vmin;
    font-weight: 500;
    ${MOBILE_CSS} {
      font-size: 5.8vmin;
      width: 90%;
    }
  }
  span.impact {
    color: #00a7ff;
    font-weight: bold;
    text-shadow: 2px 2px 5px #000;
  }
  .firstContainer {
    opacity: 0;
    ${MOBILE_CSS} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .fade-in {
    animation: fade-in 1.5s linear 0.5s forwards;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column-gap: 110px;
    margin-top: 95px;
    padding-bottom: 160px;
    ${MOBILE_CSS} {
      grid-column-gap: 40px;
    }
  }
  .valueContainer {
    width: 16vw;
    opacity: 0; /* 초기 상태: 투명 */
    transform: translateY(20px); /* 초기 상태: 아래에서 시작 */
    transition:
      opacity 1s ease 0.4s,
      transform 2s ease 0.4s; /* 애니메이션 효과 */
    text-align: center;
    img {
      //width : 5.3vw;
      max-width: 100px;
    }
    ${MOBILE_CSS} {
      width: 20vw;
    }
  }
  .valueContainer.visible {
    opacity: 1; /* 보이도록 설정 */
    transform: translateY(0); /* 원래 위치로 이동 */
  }

  .subText {
    font-size: 3.1vmin;
    color: #00a7ff;
    margin-top: 48px;
    ${MOBILE_CSS} {
    font-size: 4vmin;
      margin-top: 24px;
      height: 7vh;
      display: flex;
      align-items: center;
    }
  }
  .hash {
    margin-top: 15px;
    font-size: 3.293vmin;
    font-weight: normal;
    ${MOBILE_CSS}{
    font-size : 4vmin;
    }
  }
`;
const Notfound = styled.div`
  text-align: center;
  height: 100vh;
  position: relative;
  font-family: pretendard;
  color: #fff;
  font-weight: bold;
  font-size: 6.5vmin;
  svg {
    padding-top: 25vh;
    font-size: 20vmin;
    color: crimson;
  }
  p {
  }
  a {
    font-family: pretendard;
    color: #fff;
    font-weight: bold;
    font-size: 2.5vmin;
    padding: 1.3vh 2vw;
    background-color: unset;
    border: 3px solid #fff;
    border-radius: 3vmin;
    cursor: pointer;
    text-decoration: unset;
  }
`;
export default {
  FullSection,
  FullSubSection,
  FullCompanySection,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Notfound,
};
