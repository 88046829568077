/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import CompanyStyle from "../../style/CompanyStyle";
import { selectMobile } from "../../store/ui/uiSelector";

export default function CompanySection3() {
  const isMobile = useSelector(selectMobile);

  // const companyAreaRef = useRef<HTMLDivElement | null>(null); // 타입 지정 추가

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (companyAreaRef.current) {
  //       const scrollY = window.scrollY;
  //       companyAreaRef.current.style.backgroundPositionY = `${-130 + scrollY * 0.5}px`;
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
    
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    <CompanyStyle.Section3>

          {isMobile ? (
                  <div className="main">
                  {/* <div className="companyArea" ref={companyAreaRef}></div> */}
                  <div className="companyArea" ></div>
                  <div className="content">
            <table className="table">
              <tbody>
                <tr>
                  {/* <th>회 사 명</th> */}
                  <td>(주) 한백코리아</td>
                </tr>
                <tr>
                  {/* <th>본 사 주 소</th> */}
                  <td>(06192)<br/>서울 강남구 선릉로 428 10층 120호</td>
                </tr>
                <tr>
                  {/* <th>전 화 번 호</th> */}
                  <td>02-6225-9001</td>
                </tr>
                <tr>
                  {/* <th>메 일</th> */}
                  <td>
                    <a href="mailto:hanbaek1128@hbkorea.co.kr?subject=안녕하세요. 제품문의드립니다.">
                      <FontAwesomeIcon icon={faEnvelope} />
                      &nbsp;&nbsp;hanbaek1128@hbkorea.co.kr
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            </div>
          ) : (
            <div className="main">
        {/* <div className="companyArea" ref={companyAreaRef}></div> */}
        <div className="companyArea" ></div>
        <div className="content">
            <table className="table">
              <thead>
                <tr>
                  <th>회 사 명</th>
                  <th>본 사 주 소</th>
                  <th>전 화 번 호</th>
                  <th>메 일</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>(주) 한백코리아</td>
                  <td>(06192) 서울 강남구 선릉로 428 10층 120호</td>
                  <td>02-6225-9001</td>
                  <td>
                    <a href="mailto:hanbaek1128@hbkorea.co.kr?subject=안녕하세요. 제품문의드립니다.">
                    <FontAwesomeIcon icon={faEnvelope} />
                      &nbsp;&nbsp;hanbaek1128@hbkorea.co.kr
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
            </div>
          )}

      <div className="partnerArea">
        <div className="partner">
          <div className="overText">
            <p className="customer">PARTNER & CUSTOMER</p>
            <p className="comment">
              한백코리아는 다양한 분야의 고객 & 파트너사와 함께하고 있습니다.
            </p>
          </div>
          <div className="rollingLogo">
            <div className="logoo unroll n1">
              {partner.map((item, index) => (
                <img
                  key={`partner-${index}`} // 고유한 key prop 추가
                  src={`static/images/company_logo/${item}.png`}
                  alt={`Partner logo ${item}`} // alt 속성 추가
                />
              ))}
            </div>
            <div className="logoo unroll n2">
              {partner.map((item, index) => (
                <img
                  key={`partner-${index}-2`} // 고유한 key prop 추가
                  src={`static/images/company_logo/${item}.png`}
                  alt={`Partner logo ${item}`} // alt 속성 추가
                />
              ))}
            </div>
            <div className="logoo unroll n3">
              {partner.map((item, index) => (
                <img
                  key={`partner-${index}-4`} // 고유한 key prop 추가
                  src={`static/images/company_logo/${item}.png`}
                  alt={`Partner logo ${item}`} // alt 속성 추가
                />
              ))}
            </div>
          </div>
          <div className="rollingLogo">
            <div className="logoo setroll n1">
              {customer.map((item, index) => (
                <img
                  key={`customer-${index}`} // 고유한 key prop 추가
                  src={`static/images/company_logo/${item}.png`}
                  alt={`Customer logo ${item}`} // alt 속성 추가
                />
              ))}
            </div>
            <div className="logoo setroll n2">
              {customer.map((item, index) => (
                <img
                  key={`customer-${index}-2`} // 고유한 key prop 추가
                  src={`static/images/company_logo/${item}.png`}
                  alt={`Customer logo ${item}`} // alt 속성 추가
                />
              ))}
            </div>
            <div className="logoo setroll n3">
              {customer.map((item, index) => (
                <img
                  key={`customer-${index}-4`} // 고유한 key prop 추가
                  src={`static/images/company_logo/${item}.png`}
                  alt={`Customer logo ${item}`} // alt 속성 추가
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </CompanyStyle.Section3>
  );
}
const partner = [
  "akamai",
  "guardicore",
  "cisco",
  "appdynamics",
  "pure",
  "splunk",
];

const customer = ["posco", "poscodx", "poscointernational","seah", "seahsteal", "seahspecial"];
