/* eslint-disable react/no-danger */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "src/utils/typeCheckers";
// import Button from "~/components/Button"
import MainStyle from "src/style/MainStyle";
import { useSelector } from "react-redux";
import { selectMobile } from "src/store/ui/uiSelector";
import useIntersectionObsever from "../../hooks/useIntserctionObserver";

// import { selectMobile } from "~/store/ui/uiSelector"

interface Props {
  video: string;
  videoMobile?: string;
  imageWeb: string;
  imageMobile: string;
  title: string;
  mobileTitle?: string;
  content: string;
  mobileContent?: string;
  type?: string;
  button?: Record<string, string>;
  filter?: string;
  children?: React.ReactNode;
  section: string;
  thum?: string;
}
const FullVideoSection: React.FC<Props> = ({
  video,
  videoMobile,
  imageWeb,
  imageMobile,
  title,
  content,
  type,
  button,
  children,
  filter,
  section,
  thum,
  mobileTitle,
  mobileContent,
}) => {
  const isMobile = useSelector(selectMobile);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRefSub = useRef<HTMLVideoElement | null>(null);
  const [isPlayingSub, setIsPlayingSub] = useState(false); // 초기 상태를 false로 설정
  const refs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const isInViewport = refs.map((ref) => useIntersectionObsever(ref));
  const handleScroll = () => {
    // 현재 스크롤 위치
    const scrollY = window.scrollY;
    // 화면 크기 (120vh)
    const screenHeight = window.innerHeight * 1.2;

    if (scrollY > screenHeight && isPlaying) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      setIsPlaying(false);
    } else if (scrollY <= screenHeight && !isPlaying) {
      if (videoRef.current) {
        videoRef.current.play();
      }
      setIsPlaying(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPlaying]);

  const handleScrollSub = () => {
    if (videoRefSub.current) {
      const rect = videoRefSub.current.getBoundingClientRect();
      // 동영상이 화면에 조금이라도 보이면
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        if (!isPlayingSub) {
          videoRefSub.current.play();
          setIsPlayingSub(true);
        }
      } else {
        if (isPlayingSub) {
          videoRefSub.current.pause();
          setIsPlayingSub(false);
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScrollSub);
    return () => {
      window.removeEventListener("scroll", handleScrollSub);
    };
  }, [isPlayingSub]);

  const [typTitle, setTypTitle] = useState("");
  const [count, setCount] = useState(0);
  const completionTitle = title;

  useEffect(() => {
    if (isInViewport[0] && count < completionTitle.length) {
      const typingInterval = setInterval(() => {
        setTypTitle((prevTitleValue) => {
          return prevTitleValue + completionTitle.charAt(prevTitleValue.length);
        });

        setCount((prevCount) => prevCount + 1);
      }, 100);

      return () => {
        clearInterval(typingInterval);
      };
    }
  }, [isInViewport[0], count, completionTitle]);
  return (
    <>
      {section === "main" ? (
        <div className="section">
          <MainStyle.FullSection>
            <div className="full-content">
              {children}

              <div className="inner">
                <div
                  className={`main-section-box ${!isEmpty(type) ? type : ""}`}
                >
                  <svg width="95vw" height={isMobile ? "65vh" : "40vh"}>
                    <defs>
                      <linearGradient
                        id="gradient1"
                        gradientUnits="userSpaceOnUse"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                      >
                        <stop offset="0%" stopColor="#2491D1" />
                        <stop offset="100%" stopColor="#00A29A" />
                      </linearGradient>

                      <filter
                        id="shadow"
                        x="-20%"
                        y="-20%"
                        width="140%"
                        height="140%"
                      >
                        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                        <feOffset dx="2" dy="2" result="offsetblur" />
                        <feFlood floodColor="black" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                          <feMergeNode />
                          <feMergeNode in="SourceGraphic" />
                        </feMerge>
                      </filter>
                    </defs>
                    {isMobile ? (
                      <text
                        fontSize="10vmin"
                        fontWeight="bold"
                        fill="url(#gradient1)"
                        textAnchor="middle"
                        x="50%"
                        y="65"
                        filter="url(#shadow)"

                      >
                        <tspan x="50%" dy="0">
                          {mobileTitle != undefined
                            ? mobileTitle.split("<br/>")[0]
                            : ""}
                        </tspan>
                        <tspan x="50%" dy="1.2em">
                          {mobileTitle != undefined
                            ? mobileTitle.split("<br/>")[1]
                            : ""}
                        </tspan>
                        <tspan x="50%" dy="1.2em">
                          {mobileTitle != undefined
                            ? mobileTitle.split("<br/>")[2]
                            : ""}
                        </tspan>
                        <tspan x="50%" dy="1.2em">
                          {mobileTitle != undefined
                            ? mobileTitle.split("<br/>")[3]
                            : ""}
                        </tspan>
                        <tspan
                          x="50%"
                          dy="3.2em"
                          fontSize="4vmin"
                          fontWeight="600"
                        >
                          {mobileContent != undefined
                            ? mobileContent.split("<br/>")[0]
                            : ""}
                        </tspan>
                        <tspan
                          x="50%"
                          dy="1.2em"
                          fontSize="4vmin"
                          fontWeight="600"
                        >
                          {mobileContent != undefined
                            ? mobileContent.split("<br/>")[1]
                            : ""}
                        </tspan>
                        <tspan
                          x="50%"
                          dy="1.2em"
                          fontSize="4vmin"
                          fontWeight="600"
                        >
                          {mobileContent != undefined
                            ? mobileContent.split("<br/>")[2]
                            : ""}
                        </tspan>
                      </text>
                    ) : (
                      <text
                        fontSize="7.135vmin"
                        fontWeight="bold"
                        fill="url(#gradient1)"
                        textAnchor="middle"
                        x="50%"
                        y="65"
                        css={css`
                          text-shadow: 2px 2px 5px #000;
                        `}
                      >
                        <tspan x="50%" dy="0">
                          {title.split("<br/>")[0]}
                        </tspan>
                        <tspan x="50%" dy="1.2em">
                          {title.split("<br/>")[1]}
                        </tspan>
                        <tspan
                          x="50%"
                          dy="3.2em"
                          fontSize="2.964vmin"
                          fontWeight="600"
                        >
                          {content.split("<br/>")[0]}
                        </tspan>
                        <tspan
                          x="50%"
                          dy="1.2em"
                          fontSize="2.964vmin"
                          fontWeight="600"
                        >
                          {content.split("<br/>")[1]}
                        </tspan>
                      </text>
                    )}
                  </svg>

                  {/* <div
                    className="section-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></div>
                  <div
                    className="section-content-resizing"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div> */}
                </div>
              </div>
            </div>
            <div
              className={
                filter == "on" ? "full-page-image on" : "full-page-image"
              }
              onClick={() =>
                button?.link != undefined
                  ? (document.location.href = button.link)
                  : ""
              }
            >
              <video
                className="main-video"
                src={isMobile ? videoMobile : video}
                ref={videoRef}
                playsInline
                muted
                autoPlay
                loop
                poster={thum}
              />
            </div>
          </MainStyle.FullSection>
        </div>
      ) : section === "sub" ? (
        <div className="section">
          <MainStyle.FullSubSection>
            <div className="full-sub-content">
              {children}
              <div className="inner">
                <div
                  className={`main-section-box ${!isEmpty(type) ? type : ""}`}
                  ref={refs[0]}
                >
                  <div className="section-title">{typTitle}</div>
                  <div className="section-content-resizing">{content}</div>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: isMobile
                  ? imageMobile === undefined
                    ? `url(${imageWeb})`
                    : `url(${imageMobile})`
                  : `url(${imageWeb})`,
              }}
              className={
                filter == "on" ? "full-page-image on" : "full-page-image"
              }
              onClick={() =>
                button?.link != undefined
                  ? (document.location.href = button.link)
                  : ""
              }
            >
              <video
                className="main-video"
                src={isMobile ? videoMobile : video}
                ref={videoRefSub}
                playsInline
                muted
                autoPlay
                loop
                poster={thum}
              />
              {/* 동영상 상태 표시 */}
            </div>
          </MainStyle.FullSubSection>
        </div>
      ) : (
        <div className="section">
          <MainStyle.FullCompanySection>
            <div className="full-content">
              {children}
              <div className="inner">
                <div
                  className={`main-section-box ${!isEmpty(type) ? type : ""}`}
                >
                  <div className="section-title">{title}</div>
                  <div className="section-content-resizing">{content}</div>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: isMobile
                  ? imageMobile === undefined
                    ? `url(${imageWeb})`
                    : `url(${imageMobile})`
                  : `url(${imageWeb})`,
              }}
              className={
                filter == "on" ? "full-page-image on" : "full-page-image"
              }
              onClick={() =>
                button?.link != undefined
                  ? (document.location.href = button.link)
                  : ""
              }
            >
              <video
                className="main-video"
                src={video}
                ref={videoRef}
                playsInline
                muted
                autoPlay
                loop
                poster={thum}
              />
            </div>
          </MainStyle.FullCompanySection>
        </div>
      )}
    </>
  );
};

export default FullVideoSection;
