import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import MainStyle from "../../style/MainStyle";
import useIntersectionObsever from "../../hooks/useIntserctionObserver";
import { PATH } from "../../constants/path";




export default function MainSection1() {
  const refs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const isInViewport = refs.map((ref) => useIntersectionObsever(ref));
  return (
    <MainStyle.Notfound>
      <div>
      <FontAwesomeIcon icon={faCircleExclamation} />
      <br/><br/>
        <p className="aboutus">잘못된 접근입니다.</p>
        <br/>
        <br/>
        <Link to={PATH.ROOT} >홈으로 돌아가기</Link>

      </div>
    </MainStyle.Notfound>
  );
}
