/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import CompanyStyle from "../../style/CompanyStyle";

export default function CompanySection2() {
  const [clicked, setClicked] = useState(0);
  const [fade, setFade] = useState(false);
  function tabSection() {
    return (
      <>
        <div className="container">
        {welfareArray.map((item, index) => (
  <div
    key={item.title} // 고유한 key prop 추가 (item.id가 있을 경우 사용)
    className="valueContainer"
    onClick={() => {
      setFade(true);
      setTimeout(() => {
        setClicked(index);
        setFade(false);
      }, 250);
    }}
  >
    <p
      className="hash"
      style={
        index === clicked
          ? { color: "#fff", borderBottom: "2px solid #fff" }
          : { color: "#7e7e7e", borderBottom: "unset" }
      }
    >
      {item.title}
    </p>
  </div>
))}
        </div>
        <div className={`subDesc ${fade ? 'fade-out' : 'fade-in'}`}>
          <p >
            {welfareArray[clicked].innerText[0]}
            <br />
            <br />
            {welfareArray[clicked].innerText[1]}
          </p>

          
        </div>
      </>
    );
  }
  return (
    <CompanyStyle.Section2>
      <div>
        <p className="value">OUR WELFARE</p>
        <p className="mainContent">
          한백코리아는{" "}
          <span className="light">
            회사와 개인의 성장에 집중할 수 있도록 지원
          </span>
          합니다
        </p>
        {tabSection()}
      </div>
    </CompanyStyle.Section2>
  );
}

const welfareArray = [
  {
    title: "#소중한 순간",
    innerText: [
      "구성원의 소중한 순간은 우리 모두에게도 소중한 순간입니다",
      "구성원의 소중한 경조사를 함께 기념하며, 축하와 위로의 마음을 전합니다",
      
    ],
    grid: [
      {
        title: "축의금",
        desc: "결혼",
      },
      {
        title: "부의금",
        desc: "b",
      },
      {
        title: "생일",
        desc: "c",
      },
      {
        title: "결혼기념일",
        desc: "c",
      },
    ],
  },
  {
    title: "#성장 지원",
    innerText: [
      "구성원의 성장이 곧 회사의 성장입니다",
      "구성원의 지속적인 성장을 지원하며, 다양한 교육 프로그램을 통해 개인의 역량을 개발할 수 있도록 돕습니다",
      
    ],
    grid: [
      {
        title: "학자금 지원",
        desc: "",
      },
      {
        title: "업무 교육지원",
        desc: "",
      },
      {
        title: "자격증 취득",
        desc: "",
      },
      {
        title: "도서 구입",
        desc: "",
      },
      {
        title: "복지포인트",
        desc: "",
      },
    ],
  },
  {
    title: "#휴식과 충전",
    innerText: [
      "마음과 몸을 재충전하는 시간이 더 나은 성과로 이어집니다",
      "구성원들이 충분한 휴식을 취할 수 있도록 다양한 휴가 제도를 제공합니다",
      
    ],
    grid: [
      {
        title: "리플레시 휴가",
        desc: "",
      },
      {
        title: "심리상담 지원",
        desc: "",
      },
      {
        title: "경조 휴가",
        desc: "",
      },
    ],
  },
  {
    title: "#금융혜택",
    innerText: [
      "더 나은 삶을 위한 든든한 기반이 되어드립니다",
      "다양한 자금지원과 인센티브를 제공합니다",
      
    ],
    grid: [
      {
        title: "월세지원",
        desc: "",
      },
      {
        title: "이자지원",
        desc: "",
      },
      {
        title: "인센티브",
        desc: "",
      },
    ],
  },
];
