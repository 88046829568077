/** @jsxImportSource @emotion/react */
import React from "react";

import { Link } from "react-router-dom";

import { css } from "@emotion/react";
import SolutionStyle from "../../style/SolutionStyle";

interface LinkBtn {
  desc: string;
  Link: string;
  signatureColor: string;
}

interface Props {
  backgroundImage: string;
  title: string;
  subTitle: string;
  mainContent: string;
  mainColor: string;
  LinkBtn: LinkBtn[];
}

export default function SecuritySection1({
  backgroundImage,
  title,
  subTitle,
  LinkBtn,
  mainContent,
  mainColor,
}: Props) {
  const extractLastSegment = (link: string) => {
    const segments = link.split("/"); // 슬래시로 분리
    return segments.pop(); // 마지막 요소 반환
  };
  return (
    <SolutionStyle.Section1>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
        className="background"
      ></div>
      <div className="content">
        <div className="textArea">
          <p className="mainContent">{title}</p>
          <p className="subContent">{subTitle}</p>
        </div>
        <div className="btnArea">
          {LinkBtn.map((button, index) => (
            <Link
              className="button"
              key={index}
              to={button.Link}
              style={
                extractLastSegment(button.Link) === mainContent
                  ? { backgroundColor: mainColor, borderColor: mainColor }
                  : {}
              }
              css={css`
                text-shadow: 1px 1px 5px #000;
              `}
            >
              {button.desc}
            </Link>
          ))}
        </div>
      </div>
    </SolutionStyle.Section1>
  );
}
