/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import SolutionStyle from "../../style/SolutionStyle";

export default function SecuritySection3() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <SolutionStyle.Section3>
      <div className="gotoTop" onClick={()=>scrollToTop()}>
        상단으로 이동 <FontAwesomeIcon icon={faArrowUp} />
      </div>
      <div className="backgroundImage"></div>
      <div className="content">

        한백코리아는 고객 가치 향상을 최우선으로 생각합니다
      </div>
    </SolutionStyle.Section3>
  );
}
