/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import MetaTag from "../components/main/MetaTag";
import FullVideoSection from "../components/main/FullVideoSection";
import Layout from "../layout/Layout";
import MainSection1 from "../container/main/MainSection1";
import MainSection2 from "../container/main/MainSection2";
import MainSection3 from "../container/main/MainSection3";
import MainSection4 from "../container/main/MainSection4";
import MainSection5 from "../container/main/MainSection5";
import TopButton from "../components/TopButton";

// eslint-disable-next-line react/function-component-definition
const Main: React.FC = () => {
  return (
    <>
      <MetaTag
        keyword="한백코리아, hanbaek korea, mes, splunk, Splunk ITSI, splunk ES, splunk es, EnterpriseSecurity, enterprise security, 보안, 보안관제, 이상징후, ilms, 정보유출, it security, guardicore, zero trust, micro segmentation, 제로트러스트, 마이크로세그멘테이션, ot보안, OT보안, 산업보안, ai , aiops, 정보보호, 인공지능, NexGuard, TI, log4j, 클라우드, 스토리지, 퓨어스토리지, storage, purestorage, flash array, flash blade, pure1, purity, apm, cisco appdynamics, appdynamics, 플래시어레이, 플레시블레이드, 앱다이나믹스, php apm, scouter, big data,observability, infrastructure, 인프라, infra, it consulting, sime, incident, soc"
      />
      <Layout>
        <TopButton />
        <FullVideoSection
          video="static/images/main/mountainVideo2.mp4"
          videoMobile="static/images/main/mobileMountainVideo.mp4"
          imageWeb="static/images/main/hanbeakLogoMainVertical.svg"
          imageMobile=""
          title="Building IT Success Stories<br/>with Hanbaek Korea"
          mobileTitle="Building IT<br/>Success Stories<br/>with<br/>Hanbaek Korea"
          content="클라우드 시대에 필요한 Enterprise 빅데이터 솔루션을 기반으로<br/>차별화된 비즈니스 모델과 IT 역량을 통해 고객의 성공스토리를 함께 만들어갑니다."
          mobileContent="클라우드 시대에 필요한 Enterprise 빅데이터<br/>솔루션을 기반으로 차별화된 비즈니스 모델과<br/>IT 역량을 통해 고객의 성공스토리를 함께 만들어갑니다."
          section="main"
          thum="static/images/main/mountainVideo2_thumnail.jpg"
        />
        <MainSection1 />
        <MainSection2 />
        <MainSection5 />
        <FullVideoSection
          video="static/images/main/MainSection4_video.mp4"
          videoMobile="static/images/main/MainSection4_mobile_video.mp4"
          imageWeb=""
          imageMobile=""
          title="한백코리아는 고객의 가치 향상이 핵심 동력인 회사입니다"
          content="HANBAEK KOREA"
          section="sub"
        />
        <MainSection4 />
      </Layout>
    </>
  );
};

export default Main;
