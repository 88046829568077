/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PATH } from "../constants/path";
import SolutionsSection1 from "../container/solutions/SolutionsSection1";
import SolutionsSection2 from "../container/solutions/SolutionsSection2";
import SolutionsSection3 from "../container/solutions/SolutionsSection3";
import Layout from "../layout/Layout";
import { signatureColorSelect } from "../utils/signatureColorSelect";
import { COLOR } from "../constants/color";
import { solutionArray } from "../utils/solutionArray";
import MetaTag from "../components/main/MetaTag";
import TopButton from "../components/TopButton";

// eslint-disable-next-line react/function-component-definition
const Analytics: React.FC = () => {
  const { param } = useParams();

  return (
    <>
    <MetaTag title="Analytics" keyword="한백코리아, hanbaek korea, mes, splunk, Splunk ITSI, apm, cisco appdynamics, appdynamics, php apm, scouter, big data, observability, sime, incident, soc" />
    <TopButton/>
    <Layout>
      <SolutionsSection1
        backgroundImage="../static/images/solution/analytics/analytics.jpg"
        title="
Big Data & Observability"
        subTitle="분석 솔루션"
        LinkBtn={[
          {
            desc: "Splunk",
            Link: PATH.SPLUNK,
            signatureColor: COLOR.SPLUNK,
          },
          {
            desc: "Splunk ITSI",
            Link: PATH.SPLUNKITSI,
            signatureColor: COLOR.SPLUNKITSI,
          },
          {
            desc: "APP Dynamics",
            Link: PATH.APPDYNAMICS,
            signatureColor: COLOR.APPDYNAMICS,
          },
        ]}
        mainContent={param == undefined ? "" : param}
        mainColor={signatureColorSelect(param)}
      />
      <SolutionsSection2
        mainContent={solutionArray(param)}
        mainColor={signatureColorSelect(param)}
      />
      <SolutionsSection3 />
    </Layout>
    </>
  );
};

export default Analytics;
