/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { css } from "@emotion/react";
import React, { useState, MouseEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import LayoutStyle from "../style/LayoutStyle";
import { selectActivateLnb, selectMobile } from "../store/ui/uiSelector";
import { uiSlice } from "../store/ui/uiSlice";
import { PATH } from "../constants/path";
import { COLOR } from "../constants/color";
import { NAV } from "../constants/nav";
import { useNav } from "../hooks/useNav";
import useScrollCheck from "../hooks/useScrollCheck";
import { useI18n } from "../hooks/useI18n";

export type HeaderType = "grayScale" | "color";

interface Props {
  type: HeaderType;
}

interface DetailProps {
  active?: boolean;
}

const setMenuClass = (active: number | undefined, i: number) => {
  if (active === undefined) return "";
  return active === i ? "active" : "disabled";
};

const DetailMenuList: React.FC<DetailProps> = ({ active }) => {
  const i18n = useI18n();
  const isMobile = useSelector(selectMobile);
  const [activatedDetailNav, setDetailNav] = useState<number | undefined>(
    undefined
  );
  const onDetailMenu = (num: number | undefined, e: React.MouseEvent) => {
    if (isMobile && (e.type === "mouseenter" || e.type === "mouseleave"))
      return null;
    e.preventDefault();
    setDetailNav(num);
  };
  // 모바일화면 아래 작은 메뉴 임시주석

  return (
    <></>
    // <div className={`detail-menu-box ${active ? "active" : ""}`}>
    //   {detailMenu.map((item, i) => {
    //     return (
    //       <div
    //         key={i}
    //         onMouseEnter={(e) => onDetailMenu(i, e)}
    //         onMouseLeave={(e) => onDetailMenu(undefined, e)}
    //         className={`detail-menu ${setMenuClass(activatedDetailNav, i)}`}
    //       >
    //         <a href={item.link}>{item.name}</a>
    //         {/* { <Link to={item.link}>{item.name}</Link>} */}
    //       </div>
    //     );
    //   })}
    // </div>
  );
};

const Header: React.FC<Props> = ({ type }) => {
  const isMobile = useSelector(selectMobile);
  const activateLnb = useSelector(selectActivateLnb);
  const [memberUrl, setMemberUrl] = useState<string>();

  const i18n = useI18n();
  const { activateMenus } = useNav();
  const dispatch = useDispatch();
  const [activeMenuIndex, setMenuIndex] = useState<number | undefined>();
  const isColor = type === "color";
  const setActivateLnb = (activate: boolean) =>
    dispatch(uiSlice.actions.setActivateLnb(activate));

  const onActiveMenu = (e: MouseEvent, i: number | undefined) => {
    const index: number = i !== undefined ? i : 0;
    if (e.type === "click" && Object.values(NAV)[index].children !== undefined)
      e.preventDefault();
    setMenuIndex(activeMenuIndex === i ? undefined : i);
  };
  const scrollDown = useScrollCheck();
  return (
    <LayoutStyle.Header className={scrollDown || isColor ? "scroll-down" : ""}>
      <Link className="logo" to={PATH.ROOT}>
        로고
      </Link>
      <nav className={`nav ${activateLnb ? "active" : ""}`}>
        {Object.values(NAV).map((menu, i) => {
          const menuActivated = menu.id === activateMenus[0]?.id;

          return (
            <div
              className={`menu ${activeMenuIndex === i ? "hover" : ""}`}
              key={i}
              onMouseLeave={(e) => !isMobile && onActiveMenu(e, undefined)}
              onMouseEnter={(e) => !isMobile && onActiveMenu(e, i)}
            >
              <Link
                to={menu.link}
                onClick={(e) => {
                  isMobile && onActiveMenu(e, i);
                  setActivateLnb(!activateLnb);
                }}
                className={menuActivated ? "active" : ""}
              >
                {menu.name}
              </Link>
              {/* 서브메뉴 사용안함 */}
              {/* {menu.children && (
                <div className="sub-menu">
                  {menu.children.map(
                    (
                      subItem: { link: string; name: string },
                      subIndex: number
                    ) => {
                      return (
                        <div key={subIndex} className="sub-menu-link">
                          <Link
                            to={subItem.link}
                            className={
                              menu.id === activateMenus[0]?.id &&
                              activateMenus[1]?.name === subItem.name
                                ? "active"
                                : ""
                            }
                          >
                            {subItem.name}
                          </Link>
                        </div>
                      );
                    }
                  )}
                </div>
              )} */}
            </div>
          );
        })}
        {/* {isMobile && <DetailMenuList />} */}
      </nav>
      <div className="header-menu">
        <button type="button" onClick={() => setActivateLnb(!activateLnb)}>
          <span className="bar" />
          {/* <span className="text">MENU</span> */}
        </button>
        {/* {!isMobile && <DetailMenuList active={activateLnb} />} */}
      </div>
    </LayoutStyle.Header>
  );
};

// const getHeaderType = (isColor?: boolean, isMobile?: boolean) => {
//   return `
//     position:fixed;
//     background-color: ${isMobile ? (isColor ? "rgba(25,25,25,0.25)" : "#fff") : "#fdfdfd"};
//     .menu {
//       a {
//         color: ${isMobile ? (isColor ? "#fff" : COLOR.BLACK) : COLOR.BLACK};
//       }
//     }
//     .header-menu {
//       .text {
//         color:${isMobile && (isColor ? "#fff" : COLOR.BLACK)};
//       }
//       .bar,
//       .bar::before,
//       .bar::after {
//         background-color: ${isMobile ? (isColor ? "#fff" : COLOR.BLACK) : COLOR.BLACK};
//       }
//     }
//   `;
// };

// const langCss = {
//   langCss: css`
//     display: inline-block;
//     .main {
//       .children {
//         display: none;
//         position: absolute;
//         a {
//           color: #4e4e4e !important;
//         }
//       }
//       &:hover {
//         .children {
//           display: block;
//           background-color: white !important;
//           font-size: 30px;
//           border-radius: 3px;
//           //border : 1px solid #4e4e4e ;
//           padding-bottom: 10px;
//         }
//       }
//     }
//   `,
//   mb: css`
//     width: 100%;
//     display: flex;
//     flex-wrap: nowrap;
//     position: absolute;
//     border: unset;
//     justify-content: flex-end;
//     padding-right: 50px;
//     // bottom : 5%;
//     a {
//       border-top: unset !important;
//       margin-left: 2vw;
//       img {
//         width: 30px;
//         border: 1px solid #4e4e4e;
//       }
//     }
//   `,
// };

// const kor = {
//   css: css`
//     border-left: 1px solid #fff !important;
//     &.scroll-down {
//       border-color: #4e4e4e !important;
//       background-color: red;
//     }
//   `,
// };

export default Header;
