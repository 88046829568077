import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { PATH } from "./constants/path";
import { useRoute } from "./hooks/useRoute";
import Main from "./pages/Main";
import Company from "./pages/Company";
import ScrollToTop from "./components/ScrollToTop";
import Security from "./pages/Security";
import Infra from "./pages/Infra";
import Analytics from "./pages/Analytics";
import NotFound from "./pages/NotFound";
import { solutionArray } from "./utils/solutionArray";

// import GalleryList form "./pages/"
export default function route() {
  const location = useLocation();
  const [prevPath, setPrevPath] = useState("");
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];
    const { pathname } = location;

    // 경로가 /security, /analytics, /infra로 시작하는지 확인
    const validPaths = ["/security/", "/analytics/", "/infra/"];
    const isValidPath = validPaths.some((path) => pathname.startsWith(path));

    // 스크롤 처리
    const handleScroll = (pathPrefix: string) => {
      if (pathname.startsWith(pathPrefix)) {
        const pathName = pathPrefix.split("/")[1]; // '/'를 제거한 경로 이름
        if (prevPath === pathName) {
          setShouldScrollToTop(false);
        } else {
          setShouldScrollToTop(true);
          setPrevPath(currentPath);
        }
      }
    };

    // 각 경로에 대해 스크롤 처리
    handleScroll("/security/");
    handleScroll("/analytics/");
    handleScroll("/infra/");

    // 기본 경로 처리
    if (!isValidPath) {
      setShouldScrollToTop(true);
      setPrevPath(currentPath);
    }

    // 유효하지 않은 param 처리
    if (isValidPath && pathname.split("/").length < 3) {
      setNotFound(true); // NotFound 상태 설정
    } else if (isValidPath && (solutionArray(location.pathname.split('/')[2]).title === "")) {
      setNotFound(true); // NotFound 상태 설정
    } else {
      setNotFound(false); // 유효한 경로일 경우 NotFound 해제
    }

  }, [location, prevPath]);


  return (
    <>
      {shouldScrollToTop && <ScrollToTop />}
      {notFound ? <NotFound /> : (
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/company" element={<Company />} />
          <Route path="/security/:param" element={<Security />} />
          <Route path="/analytics/:param" element={<Analytics />} />
          <Route path="/infra/:param" element={<Infra />} />
          <Route path="/*" element={<NotFound />} /> {/* 기본 NotFound 처리 */}
        </Routes>
      )}
    </>
  );
}
