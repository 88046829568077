import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MOBILE_CSS, MOBILE_BREAK } from "src/constants/app";

const Section1 = styled.div`
  justify-content: left;
  height: 100vh;
  display: flex;
  margin-left: 16vw;
  ${MOBILE_CSS} {
    margin-left: 8vw;
  }
  .background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    opacity: 0.4;
    width: 100%;
    --fill-layer-background-media-position: fixed;
    background-size: cover;
    box-shadow: inset 35vw 0px 60px -10px rgb(0 0 0);
  }

  p {
    font-family: Pretendard;
  }
  .content {
    position: absolute;
    top: 40vh;
    ${MOBILE_CSS}{
    top: 30vh;
    }
    @media (max-width: 600px){
      top: 40vh;
    }
  }
  .textArea {
    text-align: left;
  }
  .btnArea {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .mainContent {
    font-size: 9vmin;
    font-weight: bold;
    color: white;
    margin-bottom: 5px;
    text-shadow: 3px 3px 5px #000;
    ${MOBILE_CSS}{
    font-size: 8vmin;
    }
    @media (max-width: 600px){
    font-size: 9vmin;
    }
  }
  .subContent {
    font-size: 4.051vmin;
    font-weight: 600;
    margin-bottom: 90px;
    color: white;
    margin-top: unset;
    ${MOBILE_CSS}{
    font-size : 4.6vmin;
    margin-bottom: 60px;
    }
  }
  .button {
    width: auto;
    color: #fff;
    font-family: Pretendard;
    font-size: 2.964vmin;
    font-weight: 900;
    background-color: unset;
    border: 3px solid #fff;
    padding: 1.7vh 1.6vw;
    margin-right: 15px;
    border-radius: 2vmin;
    margin-bottom: 15px;
    text-decoration: none; /* 밑줄 제거 */
    ${MOBILE_CSS} {
      padding:0.7vh 3.8vw;
      border-radius: 9px;
      margin-right: 7px;
      border: 2px solid #fff;
          font-size: 4.8vmin;
          border-radius: 3vmin;
    }
  }
`;
const Section2 = styled.div`
  background-color: rgb(9, 9, 35);
  position: relative;
  padding-top: 142px;
  font-family: pretendard;
  .content {
    opacity: 1;
  }
  .fade {
    transition:
      opacity 1s ease,
      transform 1s ease;
    opacity: 1;
  }
  .fade-in {
    opacity: 0;
  }
  .fix {
    opacity: 1;
  }
  p {
    color: white;
    margin: 0 0 0 47px;
    ${MOBILE_CSS} {
      margin: unset;
    }
  }
  .title {
    text-align: center;
    font-size: 7.015vmin;
    font-weight: bold;
    padding-bottom: 55px;
    text-shadow: 3px 3px 5px #000;
    ${MOBILE_CSS}{
    font-size : 8.5vmin;
    }
  }
  .desc {
    text-align: center;
    font-size: 5.335vmin;
    font-weight: 600;
    font-stretch: normal;
    padding-bottom: 47px;
    line-height: 1.3;
    ${MOBILE_CSS}{
    font-size : 6.5vmin;
    padding-bottom: unset;}

  }

  .subDesc {
    font-size: 3.0132vmin;
    font-weight: 500;
    text-align: center;
    ${MOBILE_CSS}{
    font-size : 4.5vmin;}
  }

  a {
    text-decoration: none;
    color: white;
  }
  nav {
    margin-top: 202px;
    background-image: url("../static/images/solution/solutionBackground.jpg");
    overflow: hidden;
    background-size: cover;
    ${MOBILE_CSS} {
      margin-top: 120px;
    }
  }
  ol:first-of-type {
    margin: 0;
    padding: 0;
    display: flex;
    height: 60vh;
    ${MOBILE_CSS} {
      height: unset;
      flex-direction: column;
    }
    .sub-menu {
      display: none;
    }
  }
  ol:first-of-type > .menu-item {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-width: 13vw;
    width: 20vw;
    flex: 0 1 auto;
    line-height: 1.2;
    cursor: pointer;
    transition: width 1.2s ease-out; /* 슥 움직이는 효과 */
    position: relative;
    font-size: 3.655vmin;
    font-weight: bold;
    ${MOBILE_CSS} {
      min-width: unset;
      width: unset;
      justify-content: center;
      flex-direction: column;
      transition: height 1.2s ease-out; /* 슥 움직이는 효과 */
          font-size: 5vmin;
    }
    p {
      width: 10vw;
      margin-top: 80px;
      pointer-events: auto;

      ${MOBILE_CSS} {
        margin-top: 5px;
      }
      svg {
        position: absolute;
        
        right: 30px;
        top: 30px;
      }
      .item {
        position: absolute;
      }
    }
    .mainText {
      text-shadow: 5px 5px 5px #000;
      ${MOBILE_CSS} {
        width: 100%;
        padding-left: 20px;
      }
      .item{
      width : 16vw;
      }
    }
    &:focus {
      width: 100vw;
      box-sizing: border-box;

      .sub-menu {
        display: block;
        transition: transform 2s ease-out;
        width: 100%;
        text-align: left;
        padding: 6vh 3vw;
        
        .desc {
          font-size: 3.655vmin;
          font-weight: 500;
          text-align: left;
          ${MOBILE_CSS}{
          font-size : 4.6vmin;
                  width: 95%;}

        }
      }
      p {
        width: auto;
        margin-top: 80px;
        pointer-events: auto;
        ${MOBILE_CSS} {
          margin-top: 5px;
        }
        
        .item {
          position: relative;
        }
      }
    }

    &:focus p {
      display: block !important;
    }
    .sub-menu {
      display: none;
    }
    .sub-menu:focus {
      display: block;
    }
  }
  .sub-menu {
    position: relative;
    text-align: right;
    overflow: hidden;

    &:after {
      font-size: 15vmin;
      color: white;
      .menu-item:hover & {
        color: wheat;
      }
      .menu-item:focus & {
        display: none;
      }
    }
    .menu-item {
      &:hover a {
        text-decoration: none;
      }
      a {
        text-decoration: none;
        line-height: 1.5;
        font-size: 4vmin;
        pointer-events: default;
      }
    }
    .desc {
      color: white;
    }
  }
  .ment {
    margin-top: 158px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 153px;
    ${MOBILE_CSS} {
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
  .mentTitle {
    line-height: 1.12;
    width: 60vw;
    font-size: 7.015vmin;
    font-weight: bold;
    ${MOBILE_CSS}{
    width : 85vw;
    font-size : 8vmin;}
  }
  .mentDesc {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 50vw;
    font-size: 2.767vmin;
    font-weight: 500;
    ${MOBILE_CSS}{
    width : 80vw;
    font-size : 4vmin;}
  }
  .imagePanel {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 35vh;
    ${MOBILE_CSS} {
      height: 25vh;
    }
  }
  button {
    width: 11vw;
    height: 5.1vh;
    font-size: 1.729vmin;
    font-weight: bold;
    color: #fff;
    font-family: pretendard;
    border-radius: 1.1vmin;
    background-color: #5cc05c;
    border: unset;
    cursor: pointer;
    text-shadow: 1px 1px 5px #000;
    ${MOBILE_CSS} {
      width: 35vw;
      height: 4.9vh;
      border-radius: 2.4vmin;
      font-size: 3.43vmin;
    }
  }
`;
const Section3 = styled.div`
  background-color: rgb(9, 9, 35);
  position: relative;
  padding-top: 78px;
  font-family: pretendard;
  color: white;
  text-align: center;
  .gotoTop {
    font-size: 2.2vmin;
    font-weight: 800;
    cursor: pointer;
    ${MOBILE_CSS}{
    font-size : 4vmin;
    }
    i {
      color: #00a7ff;
    }
  }
  .backgroundImage {
    margin-top: 240px;
    background-image: url("../static/images/solution/priority.jpg");
    background-size: cover;
    background-position: center;
    height: 335px;
    ${MOBILE_CSS} {
      margin-top: 80px;
      height: 26vh;
    }
  }
  .backgroundImage:after {
    position: absolute; /* 'absolute'로 수정 */
    content: ""; /* 가상 요소의 내용 */
    top: 338px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black; /* 배경색 설정 */
    opacity: 0.6;
    ${MOBILE_CSS} {
      top: 169px;
    }
  }
  .content {
    width: 100%;
    position: absolute;
    font-size: 6.027vmin;
    font-weight: 800;
    bottom: 130px;
    display: flex; /* Flexbox 사용 */
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    ${MOBILE_CSS} {
    font-size: 7.5vmin;
      bottom: 45px;
    }
  }
  svg {
    color: rgb(0, 167, 255);
  }
`;
export default {
  Section1,
  Section2,
  Section3,
};
