import { PATH } from "./path";

export enum NavID {
  회사소개 = "회사소개",
  분석솔루션 = "분석솔루션",
  보안솔루션 = "보안솔루션",
  인프라솔루션 = "인프라솔루션",
}

export interface NavItem {
  id?: NavID;
  name: string;
  link: string;
  children?: NavItem[];
}

export const NAV: { [key in keyof typeof NavID]: NavItem } = {
  [NavID.분석솔루션]: {
    id: NavID.분석솔루션,
    name: "분석솔루션",
    link: PATH.SPLUNK,
    // children: [
    //   {
    //     name: "SPLUNK",
    //     link: PATH.SPLUNK,
    //   },
    //   {
    //     name: "splunkITSI",
    //     link: PATH.SPLUNKITSI,
    //   },
    //   {
    //     name: "appdynamics",
    //     link: PATH.APPDYNAMICS,
    //   },
    // ],
  },
  [NavID.보안솔루션]: {
    id: NavID.보안솔루션,
    name: "보안솔루션",
    link: PATH.SPLUNKES,
    // children: [
    //   {
    //     name: "SPLUNKES",
    //     link: PATH.SPLUNKES,
    //   },
    //   {
    //     name: "WEBLOG",
    //     link: PATH.WEBLOG,
    //   },
    //   {
    //     name: "GUARDICORE",
    //     link: PATH.GUARDICORE,
    //   },
    // ],
  },
  [NavID.인프라솔루션]: {
    id: NavID.인프라솔루션,
    name: "인프라솔루션",
    link: PATH.PURESTORAGE,
    // children: [
    //   {
    //     name: "PURESTORAGE",
    //     link: PATH.PURESTORAGE,
    //   },
    // ],
  },

  [NavID.회사소개]: {
    id: NavID.회사소개,
    name: "회사소개",
    link: PATH.COMPANY,
  },
};

// export const NEWS_EVENT_SUB = NAV[NavID.공지이벤트].children!.map(({ name, link }) => {
//   return {
//     name,
//     link,
//     img: require("~/images/ico/board.png"),
//   }
// })
