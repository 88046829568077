/** @jsxImportSource @emotion/react */
import React from "react";
import { createRoot,hydrateRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import store from "./store";
import App from "./App";

const container = document.getElementById("wrap");
const root = createRoot(container!);

if (container?.hasChildNodes()) {
  hydrateRoot(
    container,
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  );
} else {
  root.render(
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  );
}
