/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SolutionsSection1 from "../container/solutions/SolutionsSection1";
import SolutionsSection2 from "../container/solutions/SolutionsSection2";
import SolutionsSection3 from "../container/solutions/SolutionsSection3";
import Layout from "../layout/Layout";
import { signatureColorSelect } from "../utils/signatureColorSelect";
import { COLOR } from "../constants/color";
import { PATH } from "../constants/path";
import { solutionArray } from "../utils/solutionArray";
import MetaTag from "../components/main/MetaTag";
import TopButton from "../components/TopButton";

// eslint-disable-next-line react/function-component-definition
const Infra: React.FC = () => {
  const { param } = useParams();
  return (
    <>
    <MetaTag title="Infra" keyword="한백코리아, hanbaek korea, 스토리지, 퓨어스토리지, storage, purestorage, flash array, flash blade, pure1, purity, 플래시어레이, 플레시블레이드"/>

    
    <TopButton/>
    <Layout>
      <SolutionsSection1
        backgroundImage="../static/images/solution/infra/infrabackground.jpg"
        title="Infrastructure"
        subTitle="인프라 솔루션"
        LinkBtn={[
          {
            desc: "PureStorage",
            Link: PATH.PURESTORAGE,
            signatureColor: COLOR.PURESTORAGE,
          },
        ]}
        mainContent={param == undefined ? "" : param}
        mainColor={signatureColorSelect(param)}
      />
      <SolutionsSection2
        mainContent={solutionArray(param)}
        mainColor={signatureColorSelect(param)}
      />
      <SolutionsSection3 />
    </Layout>
    </>
  );
};

export default Infra;
