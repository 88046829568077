import logger from "redux-logger";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { countSlice } from "./store/sample/sampleSlice";
import { uiSlice } from "./store/ui/uiSlice";
import { MOBILE_BREAK } from "./constants/app";
import { userSlice } from "./store/user/userSlice";

const rootReducer = combineReducers({
  ui: uiSlice.reducer,
  count: countSlice.reducer,
  user: userSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

window.addEventListener("resize", () => {
  const { isMobile } = store.getState().ui;
  const width = window.innerWidth;
  if (width < MOBILE_BREAK && !isMobile)
    store.dispatch(uiSlice.actions.setMobile(true));
  else if (width >= MOBILE_BREAK && isMobile)
    store.dispatch(uiSlice.actions.setMobile(false));
});

export default store;
