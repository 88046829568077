/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import TopButton from "../components/TopButton";
import SolutionsSection1 from "../container/solutions/SolutionsSection1";
import SolutionsSection2 from "../container/solutions/SolutionsSection2";
import SolutionsSection3 from "../container/solutions/SolutionsSection3";
import Layout from "../layout/Layout";
import { signatureColorSelect } from "../utils/signatureColorSelect";
import { solutionArray } from "../utils/solutionArray";
import { COLOR } from "../constants/color";
import { PATH } from "../constants/path";
import MetaTag from "../components/main/MetaTag";

// eslint-disable-next-line react/function-component-definition
const Security: React.FC = () => {
  const { param } = useParams();
  return (
    <>
      <MetaTag title="Security" keyword="한백코리아, hanbaek korea, splunk ES, splunk es, EnterpriseSecurity, enterprise security, 보안, 보안관제, 이상징후, ilms, 정보유출, it security, guardicore, zero trust, micro segmentation, 제로트러스트, 마이크로세그멘테이션, ot보안, OT보안, 산업보안, ai , aiops, 정보보호, 인공지능, NexGuard, TI, log4j"/>
      <TopButton/>
      <Layout>
        <SolutionsSection1
          backgroundImage="../static/images/solution/security/swbackground.jpg"
          title="Security"
          subTitle="보안 솔루션"
          LinkBtn={[
            {
              desc: "Splunk ES",
              Link: PATH.SPLUNKES,
              signatureColor: COLOR.SPLUNKES,
            },

            {
              desc: "Akamai-Guardicore",
              Link: PATH.GUARDICORE,
              signatureColor: COLOR.GUARDICORE,
            },
            {
              desc: "NexGuard 웹키퍼",
              Link: PATH.WEBLOG,
              signatureColor: COLOR.WEBLOG,
            },
            {
              desc: "NexGuard ILMS",
              Link: PATH.NEXGUARDILMS,
              signatureColor: COLOR.NEXGUARDILMS,
            },
            {
              desc: "NexGuard TI",
              Link: PATH.NEXGUARDTI,
              signatureColor: COLOR.NEXGUARDTI,
            },
          ]}
          mainContent={param == undefined ? "" : param}
          mainColor={signatureColorSelect(param)}
        />
        <SolutionsSection2
          mainContent={solutionArray(param)}
          mainColor={signatureColorSelect(param)}
        />
        <SolutionsSection3 />
      </Layout>
    </>
  );
};

export default Security;
