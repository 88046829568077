import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect, useState } from "react";

export const userSlice = createSlice({
  name: "UI",
  initialState: {
    // isLogin: sessionStorage.loginChk === "Y",
  },
  reducers: {},
});
