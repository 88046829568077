/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { NOTFOUND } from "dns";
import FullVideoSection from "../components/main/FullVideoSection";
import Layout from "../layout/Layout";
import Notfound from "../container/notfound/Notfound";


// eslint-disable-next-line react/function-component-definition
const Main: React.FC = () => {
  return (
    <Layout>
      <Notfound/>
    </Layout>
  );
};

export default Main;
