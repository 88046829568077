import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import MainStyle from "../../style/MainStyle";
import useIntersectionObsever from "../../hooks/useIntserctionObserver";

export default function MainSection1() {
  const refs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
  const isInViewport = refs.map((ref) => useIntersectionObsever(ref));
  return (
    <MainStyle.Section1>
      <div
        className={`firstContainer ${isInViewport[0] ? "fade-in" : ""}`}
        ref={refs[0]}
      >
        <p className="aboutus">ABOUT US</p>
        <div className="textArea">
          <p className="mainContent">우리는 고객의 요구와 문제를 이해하고</p>
          <p className="mainContent">
            <span className="impact">창의적이며 능동적인</span> 접근으로
            해결하여
          </p>
          <p className="mainContent">고객의 가치를 높입니다.</p>
        </div>
      </div>
      <div className={`container ${isInViewport[1] ? "slide-down" : ""}`}  ref={refs[1]}>
        <img
          className="section1_Logo"
          src="static/images/main/hanbeakLogoWhite.svg"
          alt="로고"
        />
        <div className="line"/>
        <div className="downArraw">
        <FontAwesomeIcon icon={faChevronDown} />
          
        </div>

      </div>
    </MainStyle.Section1>
  );
}

