/* eslint-disable no-plusplus */
import { isEmpty } from "../utils/typeCheckers";

interface Box {
  radius?: number;
  shadowType?: number[];
  shadowValue?: number;
  borderColor?: string;
}

const BoxStyle = ({
  radius = 0,
  shadowType,
  shadowValue,
  borderColor,
}: Box) => {
  const setShadow = () => {
    const setType = () => {
      return shadowType
        ?.map((item) => item + "px")
        .toString()
        .replace(/,/g, " ");
    };
    const setValue = () => {
      const setRgba = () => {
        let rgb = "";
        for (let index = 0; index < 3; index++) {
          rgb = rgb + shadowValue + ", ";
        }
        return `rgba(${rgb}0.16)`;
      };
      return isEmpty(shadowValue) ? "" : setRgba();
    };
    return isEmpty(shadowType) ? null : `box-shadow:${setType()} ${setValue()}`;
  };
  return `
    ${setShadow()};
    border-radius: ${radius}px;
    ${!isEmpty(borderColor) && `border:1px solid ${borderColor}`};
  `;
};

export default BoxStyle;
