/* eslint-disable react/jsx-curly-brace-presence */
import { css } from "@emotion/react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import LayoutStyle from "../style/LayoutStyle";
import footerLogo from "../images/img_footer_logo.png";
import { COLOR } from "../constants/color";
import { PATH } from "../constants/path";

import { selectMobile } from "../store/ui/uiSelector";

interface Footer {
  full?: boolean;
  black?: boolean;
}

const Footer: React.FC<Footer> = ({
  full = false, // 기본값 설정
  black = true, // 기본값 설정
}) => {
  const isMobile = useSelector(selectMobile);
  return (
    <LayoutStyle.Footer full={full} className="footer">
      <div className="footer_content">
        <img
          className="footer_logo"
          src={"../static/images/main/hanbeakLogoMain.svg"}
          alt="로고"
        />
        {isMobile ? (
          <div className="footer_letter">
            <p className="addres">
              한백코리아
              <br/>
              <a href="mailto:hanbaek1128@hbkorea.co.kr?subject=안녕하세요. 제품문의드립니다.">
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;hanbaek1128@hbkorea.co.kr
              </a><br/>
              (06192) 서울 강남구 선릉로 428 10층 120호<br/>02-6225-9001
            </p>
            <p className="copy">2024 ⓒ HANBAEK KOREA All rights reserved.</p>
          </div>
        ) : (
          <div className="footer_letter">
            <p className="addres">
              한백코리아 |{" "}
              <a href="mailto:hanbaek1128@hbkorea.co.kr?subject=안녕하세요. 제품문의드립니다.">
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;hanbaek1128@hbkorea.co.kr
              </a>{" "}
              | (06192) 서울 강남구 선릉로 428 10층 120호 | 02-6225-9001
            </p>
            <p className="copy">2024 ⓒ HANBAEK KOREA All rights reserved.</p>
          </div>
        )}
        
      </div>
    </LayoutStyle.Footer>
  );
};

export default Footer;
