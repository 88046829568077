import { useState, useEffect } from "react";

export default function useScrollCheck() {
  const [isDownScroll, setDownScroll] = useState<boolean>(false);
  const onScroll = () => {
    const st = window.scrollY;
    setDownScroll(st > 0);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);
  return isDownScroll;
}
