import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import Header, { HeaderType } from "./Header";
import Footer from "./Footer";

interface Props {
  headerType?: HeaderType;
  children: React.ReactNode;
}
const Layout: React.FC<Props> = ({ headerType = "grayScale", children }) => {
  return (
    <Wrap className="wrap">
      <Header type={headerType} />
      <div className="container">
        <div className="inner">{children}</div>
      </div>
      <Footer />
    </Wrap>
  );
};
// rgb(9, 9, 35)
const Wrap = styled.div`

  background-color: rgb(9, 9, 35);
  .container {

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    word-break: keep-all;
    z-index: 1;
    > .inner {
      width: 100%;
      min-height: 100%;
    }
  }
`;

export default Layout;
