import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MOBILE_CSS, CONTENT_SIZE } from "src/constants/app";

const Section1 = styled.div`
  justify-content: center;
  height: 100vh;
  display: flex;
  .background {
    position: fixed;
    top: 0;
    left: 0;
    background-image: url("static/images/company/mountain_background1.jpg");
    height: 100vh;
    opacity: 0.4;
    width: 100%;
    --fill-layer-background-media-position: fixed;
    background-size: cover;
    background-position: center;
  }

  p {
    font-family: Pretendard;
  }
  .content {
    position: absolute;
  }
  .logo {
    padding-top: 25vh;
    display: flex;
    justify-content: center;
  }
  .section1_Logo {
  }
  .textArea {
    text-align: center;
    padding-bottom: 100px;
  }
  .mainContent {
    font-size: 48px;
    font-weight: bold;
    color: white;
    margin-top: 40px;
  }
  .subContent {
    line-height: 1.5;
    margin-top: 40px;
    font-size: 24px;
    font-weight: 500;
    color: white;
  }
`;
const Section2 = styled.div`
  background-color: rgb(9, 9, 35);
  position: relative;
  padding-top: 200px;
  padding-left: 12.2vw;
  padding-right: 12.2vw;
  ${MOBILE_CSS} {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  p {
    width: auto;
    font-family: pretendard;
    margin: 0;
    text-align: center;
    color: #fff;
  }
  .value {
    font-size: 2vmin;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 50px;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }
  .textArea {
    padding-bottom: 50px;
  }
  p.mainContent {
    font-size: 45px;
    font-weight: bold;
    ${MOBILE_CSS} {
      font-size: 5.8vmin;
    }
  }
  span.impact {
    color: #00a7ff;
    font-weight: bold;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column-gap: 5.25vw;
    margin-top: 80px;
    padding-bottom: 66px;
    ${MOBILE_CSS} {
      grid-column-gap: 4.25vw;
    }
  }
  .valueContainer {
    text-align: center;
  }
  .subDesc {
    font-size: 2.964vmin;
    font-weight: 500;
    color: #fff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    ${MOBILE_CSS} {
      font-size: 4vmin;
    }
  }
  .light {
    display: inline;
    box-shadow: inset 0px -24px 0px 0px rgba(15, 207, 255, 0.5);
    text-shadow: 2px 2px 5px #000;
  }
  .hash {
    width: auto;
    font-size: 2.635vmin;
    font-weight: bold;
    cursor: pointer;
    padding: 0px 10px 23px;
    text-shadow: 2px 2px 5px #000;
    ${MOBILE_CSS} {
      font-size: 4vmin;
      padding: 0px 0px 23px;
    }
  }

  .benefitInner {
    margin-top: 69px;
  }
  .innnerGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3개의 컬럼으로 설정 */
    gap: 25px; /* 항목 간의 간격 설정 */
  }
  .each {
    height: 29vh;
    border: solid 0px #07070d;
    border-radius: 20px;
    padding: 30px 20px; /* 내부 여백 설정 */
    text-align: center; /* 텍스트 가운데 정렬 */
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 3px 2px rgb(9, 9, 20);
  }

  .ment {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 60px;
  }
  .mentTitle {
    line-height: 1.12;
    width: 60vw;
    font-size: 71px;
    font-weight: bold;
  }
  .mentDesc {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 50vw;
    font-size: 28px;
    font-weight: 500;
  }
  .benefitSubTitle {
    font-size: 30px;
    font-weight: bold;
    color: #00a7ff;
    text-shadow: 2px 2px 5px #000;
  }
  .fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }
`;
const Section3 = styled.div`
  background-color: rgb(9, 9, 35);
  position: relative;
  padding-top: 16vh;

  .main {
    padding-bottom: 287px;
    position: relative;
    height: 700px; /* 전체 화면 높이 */
    width: 100%;
    ${MOBILE_CSS} {
      height: 450px; /* 전체 화면 높이 */
      padding-bottom: 120px;
    }
  }
  p {
    font-family: pretendard;
    margin: 0;
    color: #fff;
  }

  .table {
    color: #fff;
    text-align: left;
  }
  .content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* 수평 중앙 정렬 */
    bottom: 50%; /* 원하는 위치 조정 */
    padding: 20px; /* 필요에 따라 여백 추가 */
    display: flex; /* 내용 정렬을 위한 flex 사용 */
    flex-direction: row; /* 세로 방향으로 정렬 */
    align-items: center; /* 중앙 정렬 */
    ${MOBILE_CSS} {
      flex-direction: column;
      bottom: 45%; /* 원하는 위치 조정 */
      padding: unset;
    }
  }
  .table {
    width: 80vw;
    min-width: 945px;
    font-family: pretendard;
    font-weight: normal;
    padding: 1.5vh 2.2vw;
    background-color: rgb(9, 9, 35);
    margin-right: -1px;
    ${MOBILE_CSS} {
      width: 80vw;
      padding: 10px;
      min-width: unset;
      height: 20vh;
    }
  }
  tr {
    height: auto;
    border-right: 1px solid #4e4e4e;
  }
  th {
    text-shadow: 2px 2px 5px #000;
    line-height: 1;
    padding-bottom: 20px;
    text-align: center;
    font-size: 2.635vmin;
    ${MOBILE_CSS} {
      padding-bottom: unset;
      font-size: 4vmin;
    }
  }

  td {
    text-align: center;
    font-size: 2.635vmin;
    ${MOBILE_CSS} {
      font-size: 4.1vmin;
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #00c8ff;
      }
    }
  }
  img.company {
    width: 100vw;
    height: 47vh;
  }
  .companyInfo {
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 35px;
  }
  .companyArea {
    background-image: url(../static/images/company/companyImage1.jpg);
    background-position-y: -130px;
    top: 0;
    left: 0;
    height: 600px;
    width: 100%;
    background-size: cover;

    background-attachment: fixed;
    ${MOBILE_CSS} {
      background-position-y: unset;
      height: 350px;
    }
  }
  .partner {
    position: relative; /* 자식 요소의 절대 위치를 위해 relative 설정 */
    width: 100%;
    height: 66vh;
    ${MOBILE_CSS} {
      height: 60vh;
    }
  }
  .partnerArea {
    padding-bottom: 117px;
  }
  .partner::before {
    content: ""; /* 의사 요소 생성 */
    background-image: url("static/images/company/partner.jpg");
    background-size: cover;
    position: absolute; /* 부모 요소에 대해 절대 위치 */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(8px); /* 블러 효과 */
    opacity: 0.4; /* 투명도 설정 */
    z-index: 0; /* 배경으로 설정 */
  }

  .overText {
    position: relative; /* 텍스트를 최상위로 올리기 위해 relative 설정 */
    z-index: 1; /* 텍스트를 가장 위로 */
  }
  .overText {
    text-align: center;
    padding-top: 10vh;
    ${MOBILE_CSS} {
      padding-top: 2.5vh;
    }
  }
  .customer {
    font-size: 2vmin;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 3.6px;
    margin-bottom: 50px;
    ${MOBILE_CSS} {
      font-size: 4vmin;
      margin-bottom: 25px;
    }
  }
  .comment {
    font-size: 3vmin;
    font-weight: 500;
    text-shadow: 2px 2px 5px #000;
    ${MOBILE_CSS} {
      font-size: 5vmin;
    }
  }
  .rollingLogo {
    margin-top: 100px;
    padding-left: 4.1vw;
    padding-right: 4.1vw;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    white-space: nowrap;
    ${MOBILE_CSS} {
      margin-top: 60px;
    }
  }
  .rollingLogo > img {
    height: 60px;
    ${MOBILE_CSS} {
      height: 45px;
    }
  }
  .rollingLogo > div > img {
    height: 60px;
    margin-left: 35px;
    ${MOBILE_CSS} {
      height: 45px;
    }
  }
  .rollingLogo > div {
    transform: translateX(0); /* 초기 위치 설정 */
    -webkit-transform: translateX(0); /* Safari 지원 */
  }
  .setroll {
    -webkit-animation: setroll 20s linear infinite; /* Safari 지원 */
    animation: setroll 20s linear infinite;
    animation-delay: 0.5s; /* 첫 사이클 지연 */
  }
  .unroll {
    -webkit-animation: unroll 20s linear infinite;
    animation: unroll 20s linear infinite;
    animation-delay: 0.5s; /* 첫 사이클 지연 */
  }
  
  @-webkit-keyframes setroll {
    0% {
      transform: translateX(0); /* 시작 위치 */
    }
    100% {
      transform: translateX(-100%); /* 끝 위치 */
    }
  }

  @-webkit-keyframes unroll {
    0% {
      transform: translateX(-100%); /* 시작 위치 */
    }
    100% {
      transform: translateX(0); /* 끝 위치 */
    }
  }

`;
export default {
  Section1,
  Section2,
  Section3,
};
