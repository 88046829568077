/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { MouseEvent, useEffect, useState } from "react";
import { MOBILE_CSS } from "../constants/app";
// import upButton from "~/images/btn_main_up.png"

export default function TopButton() {
  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태
  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if (ScrollY > 100) {
      // 100 이상이면 버튼이 보이게
      setBtnStatus(true);
    } else {
      // 100 이하면 버튼이 사라지게
      setBtnStatus(false);
    }
  };
  const handleTop = () => {
    // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollY(0); // ScrollY 의 값을 초기화
    setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
  };
  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", handleFollow);
    };
    watch();
    return () => {
      window.removeEventListener("scroll", handleFollow);
    };
  });
  return (
    <button
      css={BtnStatus ? style.btnActive : style.btnNull}
      type="button"
      onClick={handleTop}
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </button>
  );
}

const style = {
  btnActive: css`
    position: fixed;
    opacity: 1;
    bottom: 40px;
    right: 40px;
    z-index: 10;
    transform: translateX(-50%);
    border: 2px solid;
    border-radius: 50%;
    width: 2.2%;
    height: 4.4%;
    color: #00a7ff;
    border-color: #00a7ff;
    background-color: unset;
    cursor: pointer;

    transition: opacity 0.3s ease-in;
    animation: scrollAni 2s ease-in-out reverse;
    animation-iteration-count: infinite;
    -webkit-animation: scrollAni 2s ease-in-out reverse;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: scrollAni 2s ease-in-out reverse;
    -moz-animation-iteration-count: infinite;
    svg {
      font-size: 2.5vmin;
      transition:unset;
      animation: unset;
      animation-iteration-count: unset;
      -webkit-animation: unset;
      -webkit-animation-iteration-count:unset;
      -moz-animation:unset;
      -moz-animation-iteration-count:unset;
${MOBILE_CSS}{
font-size: 4vmin;
}
    }
    &:hover &:focus &:active {
      outline: 0 none;
    }
    @keyframes scrollAni {
      0% {
        bottom: 30px;
      }

      25% {
        bottom: 40px;
      }
      50% {
        bottom: 30px;
      }
      75% {
        bottom: 40px;
      }
      100% {
        bottom: 30px;
      }
    }
    @-webkit-keyframes scrollAni {
      0% {
        bottom: 30px;
      }
      25% {
        bottom: 40px;
      }
      50% {
        bottom: 30px;
      }
      75% {
        bottom: 40px;
      }
      100% {
        bottom: 30px;
      }
    }
    @-moz-keyframes scrollAni {
      0% {
        bottom: 30px;
      }
      25% {
        bottom: 40px;
      }
      50% {
        bottom: 30px;
      }
      75% {
        bottom: 40px;
      }
      100% {
        bottom: 30px;
      }
    }
    ${MOBILE_CSS} {
     width : 45px;
     height: 45px;
     right: -3%;
     
    }
  `,
  btnNull: css`
    display: none;
  `,
};
