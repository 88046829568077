/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import { Helmet } from "react-helmet-async";

interface MetaTagProps {
  title?: string;
  description?: string;
  imgSrc?: string;
  url?: string;
  keyword?: string;
}

const DEPLOY_URL = "https://www.hbkorea.co.kr";

export default function MetaTag(props: MetaTagProps) {
  const url = props.url ? `${DEPLOY_URL}${props.url}` : DEPLOY_URL;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{(props.title && `한백코리아 - ${props.title}`) || "한백코리아"}</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title || "한백코리아"} />
      <meta property="og:site_name" content="한백코리아" />
      <meta property="og:description" content={props.description || "Building Success IT Stories with Hanbaek Korea"} />
      <meta property="og:image" content={props.imgSrc || "https://www.hbkorea.co.kr/logo_OG.png"} />
      <meta property="og:url" content={url} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={props.description || "Building Success IT Stories whith Hanbek Korea"} data-react-helmet="true"/>
      <meta name="robots" content="index, follow"></meta>
      <meta name="keywords" content={props.keyword || ""}></meta>
    </Helmet>
  );
}