/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PATH } from "src/constants/path";
import MainStyle from "../../style/MainStyle";

export default function MainSection4() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // 요소가 보이면 더 이상 관찰하지 않음
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <MainStyle.Section4>
      <div
        ref={ref}
        className={`content ${isVisible ? "fade-in" : ""}`} // 조건부 클래스 추가
      >
        <p className="inProgress">OUR MAKING</p>
        <p className="title">
          컨설팅, 구축, 통합 기술지원이 가능한{" "}
          <span className="impact">한백코리아</span>를 선택하십시오
        </p>
        <p className="desc">
          각 솔루션의 전문가들은 전략적 지침과 검증된 기술을 제안하여 혁신
          목표의 실현을 앞당길 수 있도록 지원합니다.
        </p>
      </div>
    </MainStyle.Section4>
  );
}
