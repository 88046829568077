/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import SolutionStyle from "../../style/SolutionStyle";
import useIntersectionObsever from "../../hooks/useIntserctionObserver";
import { selectMobile } from "../../store/ui/uiSelector";

interface Props {
  mainContent: {
    title: string;
    desc: {
      desc1: string;
      desc2: string;
    };
    subDesc: string;
    merit: {
      title: string[];
      desc: string[];
    };
    mentTitle: string;
    mentDesc: string;
    solutionURL: string;
    solutionImage: string;
  };
  mainColor: string;
}
export default function SecuritySection2({ mainContent, mainColor }: Props) {
  const [refs, setRefs] = useState([
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ]);
  
  const descRefs = useRef<(HTMLDivElement | null)[]>([]);
  const isInViewport = refs.map((ref) => useIntersectionObsever(ref));
  const isMobile = useSelector(selectMobile);
      
  const [onFocus, setOnFocus] = useState(-1);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null); // 상태 타입을 number | null로 설정
  const param = useParams();
  function handleMenuClick(index: number) {
    setExpandedIndex(expandedIndex === index ? null : index); // 클릭 시 확장/축소
    if (expandedIndex === index) {
      setOnFocus(-1);
    } // 클릭 시 확장/축소
    else {
      setOnFocus(index);
    }
  }
  function loop() {
    return (
      <>
        <nav className="menu">
          {isMobile ? (
            <ol>
              {mainContent.merit.title.map((item, index) => (
                <li
                  key={index}
                  className="menu-item"
                  tabIndex={index + 1}

                  style={{
                    backgroundColor: `rgba(0, 0, 0, ${1 - (index + 1) / mainContent.merit.title.length}) `,
                    height: `${50 / mainContent.merit.title.length}vh`,
                  }}
                  
                  css={css`
                    &:focus {
                    height: ${onFocus === index ? (descRefs.current[index]!.scrollHeight + 165) + 'px' : ''} !important

                    }
                  `}
                  onFocus={() => setOnFocus(index)}
                  onBlur={() => setOnFocus(-1)}
                  onClick={() => handleMenuClick(index)}
                >
                  <p className="mainText">
                    <FontAwesomeIcon
                      icon={onFocus == index ? faMinus : faPlus}
                    />
                    Merit {String(index + 1).padStart(2, "0")}. &nbsp;{item}
                  </p>
                  <ol className="sub-menu">
                    <div
                      className="desc"
                      ref={el => descRefs.current[index] = el} // 각 요소의 ref 저장
                      dangerouslySetInnerHTML={{
                        __html: mainContent.merit.desc[index],
                      }}
                    ></div>
                    {}
                  </ol>
                </li>
              ))}
            </ol>
          ) : (
            <ol>
              {mainContent.merit.title.map((item, index) => (
                <li
                  key={index}
                  className="menu-item"
                  tabIndex={index + 1}
                  style={{
                    backgroundColor: `rgba(0, 0, 0, ${1 - (index + 1) / mainContent.merit.title.length}) `,
                    width: `${100 / mainContent.merit.title.length}vw`,
                  }}
                  css={css`
                    &:focus {
                      width: 100vw !important;
                    }
                  `}
                  onFocus={() => setOnFocus(index)}
                  onBlur={() => setOnFocus(-1)}
                >
                  <p className="mainText">
                    <FontAwesomeIcon
                      icon={onFocus == index ? faMinus : faPlus}
                    />
                    <br />
                    <br />
                    <span>Merit </span>
                    <br />
                    {String(index + 1).padStart(2, "0")}.
                    <br />
                    <br />
                    <span className="item">{item}</span>
                    <br />
                    <br />
                    <br />
                    {/* menuItems의 총 개수 출력 */}
                  </p>
                  <ol className="sub-menu">
                    <div
                      className="desc"
                      dangerouslySetInnerHTML={{
                        __html: mainContent.merit.desc[index],
                      }}
                    ></div>
                  </ol>
                </li>
              ))}
            </ol>
          )}
        </nav>
        <div className="ment">
          <div className="mentTitle">{mainContent.mentTitle}</div>
          <div className="mentDesc">{mainContent.mentDesc}</div>
          {mainContent.solutionURL == "" ? (
            <div></div>
          ) : (
            <button
              onClick={() => window.open(mainContent.solutionURL)}
              style={{ backgroundColor: mainColor }}
            >
              사이트 바로가기
            </button>
          )}
        </div>
        <div
          className="imagePanel"
          style={{
            backgroundImage: `url(${mainContent.solutionImage})`,
          }}
        ></div>
      </>
    );
  }
  return (
    <SolutionStyle.Section2>
      <div className="content" ref={refs[0]}>
        <p className="title">{mainContent.title}</p>
        <div className="desc">
          <p className="desc1">{mainContent.desc.desc1}</p>
          <p
            className="desc2"
            css={css`
              span {
                color: ${mainColor};
                font-weight: bold;
                text-shadow: 5px 5px 5px #000;
              }
            `}
            dangerouslySetInnerHTML={{
              __html: mainContent.desc.desc2,
            }}
          ></p>
        </div>
        <p className="subDesc">{mainContent.subDesc}</p>
      </div>
      {loop()}
    </SolutionStyle.Section2>
  );
}
