import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { NAV, NavItem } from "../constants/nav";
import { PATH } from "../constants/path";

export const useNav = () => {
  const { pathname } = useLocation();
  let isMain = false;
  const result = useMemo(() => {
    isMain = pathname === PATH.ROOT;
    const memo: Pick<NavItem, "id" | "link" | "name">[] = [];
    function recursive({ id, children, name, link }: NavItem): boolean {
      if (children?.some(recursive)) {
        memo.unshift({ id, name, link });
        return true;
      }
      if (link !== PATH.ROOT && matchPath(link, pathname)) {
        memo.unshift({ id, name, link });
        return true;
      }

      return false;
    }
    Object.values(NAV).some((menu) => {
      return recursive(menu);
    });
    return { isMain, activateMenus: memo };
  }, [pathname]);

  return result;
};
